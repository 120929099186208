import {Box, Button, Grid, Typography} from "@mui/material";
import * as _ from "lodash";
import {ResponsiveBar} from "@nivo/bar";
import {useContainerDimensions} from "../useContainerDimensions";
import * as React from "react";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {generateScaledPalette} from "../../../../../../utils/colorPaletteUtils";
import {normalizeString} from "../../../../../../utils/text";
import {useTranslation} from "../../../../../providers/TranslationProvider";
import CustomTick from "./CustomBarTick";
import {CustomBarComponent} from "./CustomBar";
import {CustomTooltip} from "./CustomTooltip";
import CustomLegend from "./CustomLegend";
import SubdirectoryArrowRightIcon
  from '@mui/icons-material/SubdirectoryArrowRight';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import {
  setSegmentationFilter,
  setTreeMapFilter
} from "../../../../../../store/appSlice";


const BarGraph = ({data, policy, segmentationVariables}) => {
  const [containerRef, dimensions] = useContainerDimensions();
  const [barData, setBarData] = useState(data?.data || []);
  const [keys, setKeys] = useState(data?.keys?.length ? data.keys : ['value']);
  const [indexBy, setIndexBy] = useState(data?.indexBy || []);
  const currentColorSchemeName = useSelector(state => state.app.treeMapColorScheme);
  const [currentColorScheme, setCurrentColorScheme] = useState(generateScaledPalette(currentColorSchemeName, policy?.topics?.length));
  const [height, setHeight] = useState(dimensions.height)
  const [referenceKey, setReferenceKey] = useState('value');
  const [graphScrollable, setGraphScrollable] = useState(false);
  const [selectedNodeData, setSelectedNodeData] = useState(null);
  const [selectedParentNodeData, setSelectedParentNodeData] = useState(null);
  const [baseData, setBaseData] = useState(null);
  const [selectedTopic, setSelectedTopic] = useState(null);
  const [selectedParentTopic, setSelectedParentTopic] = useState(null);
  const activeSegmentationFilters = useSelector(state => state.app.segmentationFilter);
  const referenceAggTopicSegment = useSelector(state => state.app.referenceAggTopicSegment);
  const treeMapFilter = useSelector(state => state.app.treeMapFilter);
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const [tooltipData, setTooltipData] = useState(null);

  const computeHeight = () => {
    let defaultHeight = dimensions.height - 25;
    const estimatedChartHeight = barData?.length * ((keys?.length * 1.2) || 1) * 35;
    if (estimatedChartHeight > defaultHeight) {
      defaultHeight = estimatedChartHeight;
      setGraphScrollable(true);
    }
    return defaultHeight;
  }

  function generateGradientColors(baseColor, gradientSteps = 5) {
    let r, g, b;

    // Check if the color is in hexadecimal format
    if (baseColor.startsWith('#')) {
      // Convert hex to RGB
      const hex = baseColor.replace('#', '');
      const bigint = parseInt(hex, 16);
      r = (bigint >> 16) & 255;
      g = (bigint >> 8) & 255;
      b = bigint & 255;
    } else {
      // Otherwise, assume it's in RGB format and extract components
      const match = baseColor.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/);
      if (!match) {
        throw new Error('Invalid color format');
      }
      [, r, g, b] = match.map(Number);
    }
    const factor = 0.25;
    const adjustFactors = Array.from({length: gradientSteps}, (_, i) => 1 + (i + 1) * factor);

    return adjustFactors.map((adjustFactor) => {
      return `rgb(${Math.min(Math.round(r * adjustFactor), 255)},
                 ${Math.min(Math.round(g * adjustFactor), 255)},
                 ${Math.min(Math.round(b * adjustFactor), 255)})`;
    });
  }

  useEffect(() => {
    if (data && data?.data && baseData) {
      let localData = _.cloneDeep(baseData)
      let colorPalette = generateScaledPalette(currentColorSchemeName, policy.topics.length).reverse();
      if (data?.keys?.length > 1||referenceAggTopicSegment[policy.id]) {
        if (baseData.length < data.keys.length) {
          colorPalette = generateScaledPalette(currentColorSchemeName, data.keys.length).reverse()
        } else {
          colorPalette = colorPalette.slice(0, data.keys.length)
        }

        let localReferenceKey = data.keys[0]
        setReferenceKey(localReferenceKey);
        let keyTotals = {}
        let maxKeyLength = 0;
        data.keys.forEach((key) => {
          keyTotals[key] = localData.reduce((sum, d) => sum + d[key], 0)
          maxKeyLength = Math.max(maxKeyLength, key.length);
        });
        localData = localData.sort((a, b) => b[localReferenceKey] - a[localReferenceKey]);
        localData.forEach((d) => {
          data.keys.forEach((key, j) => {
            const originalValue = d[key];
            d[key] = keyTotals[key] === 0 ? 0 : (d[key] / keyTotals[key]) * 100;
            d[`color_${key}`] = colorPalette[j];
            d[`countAnswers_${key}`] = originalValue || 0
            d[`tooltipText_${key}`] = `${d[data.indexBy]}/${key}: ${(originalValue || 0).toLocaleString("en-US")} (${(d[key]).toFixed(0)}%)`;
          });

          const data_topic = policy.topics.find(topic => topic.topic === d[data.indexBy]);
          d.aiSummary = data_topic?.summary || '';
          d.isParent = data_topic?.subtopics?.length > 0;
          d.icon = data_topic?.icon;
          d.countSubtopics = data_topic?.subtopics?.length || 0;
        });
      } else {
        setReferenceKey('value');
        localData = localData.sort((a, b) => b.value - a.value);
        let valueTotal = localData.reduce((sum, d) => sum + d.value, 0);
        if (selectedParentNodeData && selectedParentTopic?.subtopics?.length > 0) {
          colorPalette = generateGradientColors(selectedParentNodeData.data.color, selectedParentTopic.subtopics.length);
        }
        localData.forEach((d, i) => {
          let dataColor = colorPalette[i];
          const originalValue = parseInt(d.value.toFixed(0));
          d.value = (originalValue / valueTotal) * 100
          d.countAnswers = originalValue
          d.color = dataColor;
          d.tooltipText = `${d[data.indexBy]} (${(d.value).toFixed(0)}%)`;
          let topicFound = policy.topics.find(topic => normalizeString(topic.topic) === normalizeString(d[data.indexBy]));
          if (!topicFound) {
            for (let t of policy.topics) {
              if (t.subtopics?.length > 0) {
                topicFound = t.subtopics.find(st => normalizeString(st.topic) === normalizeString(d[data.indexBy]));
                if (topicFound) {
                  break;
                }
              }
            }
          }
          d.aiSummary = topicFound?.summary || '';
          d.isParent = topicFound?.subtopics?.length > 0;
          d.countSubtopics = topicFound?.subtopics?.length || 0
          d.icon = topicFound?.icon || null;
        })
      }

      setBarData(localData);
      setKeys(data?.keys?.length ? data.keys : ['value']);
      setIndexBy(data.indexBy)
      setCurrentColorScheme(colorPalette);
    }
  }, [baseData])

  useEffect(() => {
    if (data && data?.data) {
      let localData = _.cloneDeep(data.data);
      if (!selectedNodeData || (!selectedNodeData?.data.isParent && !selectedParentNodeData)) {
        let collapsedData = [];
        for (let topic of policy.topics) {
          if (topic.subtopics?.length > 0) {
            let topicData = {
              [data.indexBy]: topic.topic,
              value: 0,
            }
            for (let subtopic of topic.subtopics) {
              let stData = localData.find(d => normalizeString(d[data.indexBy]) === normalizeString(subtopic.topic));
              if (stData) {
                topicData.value += stData.value;
                if (data?.keys?.length > 0) {
                  for (let key of data.keys) {
                    topicData[key] = stData[key];
                  }
                }
              }
            }
            collapsedData.push(topicData);
          } else {
            let tData = localData.find(d => d[data.indexBy] === topic.topic);
            if (tData) {
              collapsedData.push(tData);
            }
          }
        }
        localData = collapsedData.sort((a, b) => b.value - a.value);
      } else {
        let localSelectedTopic = policy.topics.find(topic => normalizeString(topic.topic) === normalizeString(selectedNodeData.indexValue));
        let filteredData = []
        if (localSelectedTopic?.subtopics?.length > 0) {
          for (let subtopic of localSelectedTopic.subtopics) {
            let stData = localData.find(d => normalizeString(d[data.indexBy]) === normalizeString(subtopic.topic));
            if (stData) {
              stData.aiSummary = subtopic?.summary || '';
              stData.icon = subtopic?.icon || null;
              filteredData.push(stData);
            }
          }
          localData = filteredData
        } else {
          let selectedSubtopic = null;
          let parentTopic = null;
          policy.topics.forEach(topic => {
            if (selectedSubtopic) {
              return false;
            }
            if (topic.subtopics?.length > 0) {
              topic.subtopics.forEach(subtopic => {
                if (subtopic.topic === selectedNodeData.indexValue) {
                  selectedSubtopic = subtopic;
                  parentTopic = topic;
                  return false;
                }
              })
            }
          });
          if (selectedSubtopic && parentTopic) {
            for (let subtopic of parentTopic.subtopics) {
              let stData = localData.find(d => normalizeString(d[data.indexBy]) === normalizeString(subtopic.topic));
              if (stData) {
                stData.aiSummary = subtopic?.summary || '';
                stData.icon = subtopic?.icon || null;
                filteredData.push(stData);
              }
            }
            localData = filteredData
          }
        }
      }
      setBaseData(localData)
    }
  }, [data, selectedTopic]);

  useEffect(() => {
    if (barData?.length > 0) {
      let newPalette = generateScaledPalette(currentColorSchemeName, barData.length);
      if (barData.length < keys.length && keys.length > 1) {
        newPalette = generateScaledPalette(currentColorSchemeName, keys.length);
      } else if (keys.length > 1) {
        newPalette = newPalette.slice(0, keys.length)
      }

      setCurrentColorScheme(newPalette);
    }
  }, [currentColorSchemeName]);

  function referenceKeyOnTop(keys) {
    const localKeys = [...keys];
    const index = localKeys.indexOf(referenceKey);
    if (index === -1) {
      console.warn('Element not found in the array');
      return localKeys;
    }
    localKeys.splice(index, 1); // Remove the element from its current position
    localKeys.unshift(referenceKey); // Add the element to the beginning of the array
    return localKeys
  }


  useEffect(() => {
    setHeight(computeHeight());
  }, [dimensions, keys]);

  const [isTreeMapFilterProcessed, setIsTreeMapFilterProcessed] = useState(false);

  useEffect(() => {
    if (!selectedNodeData && !isTreeMapFilterProcessed) return;
    const classificationVariable = `${policy.answerVar}_label`;
    const aggregationSegmentVariable = referenceAggTopicSegment[policy.id] || null;
    const localTreeMapFilter = {...treeMapFilter};
    const localSegmentationFilters = {...activeSegmentationFilters};
    if (!selectedNodeData) {
      if (aggregationSegmentVariable) {
        delete localSegmentationFilters[aggregationSegmentVariable];
        dispatch(setSegmentationFilter(localSegmentationFilters));
      }
      if (localTreeMapFilter[policy.id]) {
        localTreeMapFilter[policy.id] = {};
        dispatch(setTreeMapFilter(localTreeMapFilter));
      }
      setSelectedTopic(null);
      setSelectedParentTopic(null);
    } else {
      const selectedTopic = policy.topics.find(topic => normalizeString(topic.topic) === normalizeString(selectedNodeData.indexValue));
      if (selectedTopic) {
        setSelectedTopic(selectedTopic);
        setSelectedParentTopic(selectedTopic);
        let classificationFilters = [selectedTopic.topic];
        if (selectedTopic.subtopics?.length > 0) {
          classificationFilters = selectedTopic.subtopics.map(subtopic => subtopic.topic);
        } else {
          if (aggregationSegmentVariable) {
            localSegmentationFilters[aggregationSegmentVariable] = [selectedNodeData.id];
            dispatch(setSegmentationFilter(localSegmentationFilters));
          }
        }
        if (localTreeMapFilter[policy.id]?.[classificationVariable] !== classificationFilters) {
          localTreeMapFilter[policy.id] = {
            [classificationVariable]: classificationFilters
          };
          dispatch(setTreeMapFilter(localTreeMapFilter));
        }
      } else {
        let selectedSubtopic = null;
        let parentTopic = null;
        policy.topics.forEach(topic => {
          if (selectedSubtopic) {
            return false;
          }
          if (topic.subtopics?.length > 0) {
            topic.subtopics.forEach(subtopic => {
              if (subtopic.topic === selectedNodeData.indexValue) {
                selectedSubtopic = subtopic;
                parentTopic = topic;
                return false;
              }
            })
          }
        });
        setSelectedTopic(selectedSubtopic);
        if (selectedSubtopic && parentTopic) {
          if (aggregationSegmentVariable) {
            localSegmentationFilters[aggregationSegmentVariable] = [selectedNodeData.id];
            dispatch(setSegmentationFilter(localSegmentationFilters));
          }
          let classificationFilters = [selectedSubtopic.topic];
          setSelectedParentTopic(parentTopic);
          if (localTreeMapFilter[policy.id]?.[classificationVariable] !== classificationFilters) {
            localTreeMapFilter[policy.id] = {
              [classificationVariable]: classificationFilters
            };
            dispatch(setTreeMapFilter(localTreeMapFilter));
          }
        }

      }
    }
  }, [selectedNodeData]);


  useEffect(() => {
    // Early return if there's no treeMapFilter for the current policy
    if (!treeMapFilter[policy.id]?.[`${policy.answerVar}_label`]?.length) {
      setSelectedNodeData(null);
      return;
    }
    if (!baseData) return;
    const classificationVariable = `${policy.answerVar}_label`;
    const filters = treeMapFilter[policy.id]?.[classificationVariable] || [];
    const isSelectedTopicAligned = selectedTopic && filters.includes(selectedTopic.topic);

    if (!selectedNodeData && !isSelectedTopicAligned) {
      // Find the topic or subtopic that matches the treeMapFilter
      let matchedTopic = null;
      let matchedSubtopic = null;

      // Loop through the topics and subtopics to find a match
      for (let topic of policy.topics) {
        if (filters.find(f => normalizeString(f || '') === normalizeString(topic.topic))) {
          matchedTopic = topic;
          break;
        }
        if (topic.subtopics?.length > 0) {
          for (let subtopic of topic.subtopics) {
            if (filters.find(f => normalizeString(f || '') === normalizeString(subtopic.topic))) {
              if (filters.length === 1) {
                matchedSubtopic = subtopic;
              }

              matchedTopic = topic;
              break;
            }
          }
        }
        if (matchedSubtopic) break;
      }

      if (matchedTopic?.subtopics?.length === 0) {
        setSelectedTopic(matchedTopic);
        const topicData = barData.find((d) => normalizeString(d[data.indexBy]) === normalizeString(matchedTopic?.topic));
        setSelectedNodeData({
          indexValue: matchedTopic.topic,
          id: "value",
          data: topicData
        });
      } else if (matchedTopic) {
        const localSubtopics = matchedTopic.subtopics
        const getSubtopic = localSubtopics?.find((item) => item.topic === matchedSubtopic?.topic);
        setSelectedTopic(matchedSubtopic);
        setSelectedParentTopic(matchedTopic);
        const subtopicData = barData.find((d) => normalizeString(getSubtopic ? getSubtopic.topic : d[data.indexBy]) === normalizeString(matchedSubtopic?.topic || ''));
        const parentTopicData = barData.find((d) => normalizeString(d[data.indexBy]) === normalizeString(matchedTopic?.topic || ''));

        if (parentTopicData) {
          setSelectedParentNodeData({
            indexValue: matchedTopic.topic,
            id: "value",
            data: {...parentTopicData, isParent: true}
          });
        }
        if (subtopicData) {
          setSelectedNodeData({
            indexValue: matchedSubtopic?.topic,
            id: "value",
            data: {...subtopicData, isParent: false}
          });
        } else if (parentTopicData) {
          setSelectedNodeData({
            indexValue: matchedTopic.topic,
            id: "value",
            data: {...parentTopicData, isParent: true}
          });
        }
      }
    }
    setIsTreeMapFilterProcessed(true);
  }, [treeMapFilter, barData]);


  const theme = {
    axis: {
      ticks: {
        text: {
          fontSize: 14, // Set your desired font size
          fontFamily: 'Montserrat', // Set your desired font family
        },
      },
      legend: {
        text: {
          fontSize: 14, // Set your desired font size
          fontFamily: 'Montserrat', // Set your desired font family
        },
      },
    },
    legends: {
      text: {
        fontSize: 14, // Set your desired font size
        fontFamily: 'Montserrat', // Set your desired font family
      },
    },
    labels: {
      text: {
        fontSize: 12, // Set your desired font size for labels
        fontFamily: 'Montserrat', // Set your desired font family for labels
      },
    },
    barComponent: {
      borderRadius: 10,
    }
  }

  useEffect(() => {
    if (tooltipData) {
      // Set a timer to hide the tooltip after 15 seconds
      const timer = setTimeout(() => {
        setTooltipData(null);
      }, 15000);

      // Cleanup the timer if the tooltipData changes or component unmounts
      return () => clearTimeout(timer);
    }
  }, [tooltipData]);

  return (
    <Box ref={containerRef} width="100%" height="100%" display="flex"
         flexDirection="column"
         style={{overflowY: 'auto', overflowX: 'hidden'}}>
      {barData?.length > 0 && (
        <>
          <Box width="100%" display="flex" justifyContent={'center'} mb={2}
               style={{marginLeft: '20px'}}>
            <Grid container direction="row" sx={{width: '100%'}}
                  justifyContent="flex-start">
              <Grid container item direction="row"
                    sx={{width: '100%', flexWrap: 'nowrap'}}
                    alignItems="center">
                <Grid id="bar-breadcrumb" item sx={{
                  marginTop: '10px',
                  width: keys?.length > 1 ? '210px' : '100%'
                }}>
                  <Button
                    sx={{
                      fontFamily: 'Montserrat',
                      fontSize: '14px',
                      fontWeight: '500',
                      color: '#717171',
                      '&:hover': {
                        backgroundColor: 'transparent'
                      },
                      padding: 0,
                      textTransform: 'none',
                      letterSpacing: '0.0025em',
                      lineHeight: '17px',
                      fontStyle: 'normal',
                    }}
                    startIcon={<FiberManualRecordIcon
                      sx={{width: '10px', height: '10px'}}/>}
                    onClick={() => {
                      setSelectedNodeData(null);
                      setSelectedTopic(null);
                      setSelectedParentNodeData(null);
                      const localTreeMapFilter = {...treeMapFilter};
                      localTreeMapFilter[policy.id] = {};
                      dispatch(setTreeMapFilter(localTreeMapFilter));
                    }}>
                    {t('all_topics')} {(policy.maxClassificationTopics || 1) === 1 ? '(100%)' : ''}
                  </Button>
                  {selectedParentNodeData?.data?.isParent && selectedParentTopic && (
                    <Box sx={{
                      marginLeft: '0px',
                      display: 'flex',
                      marginTop: '5px',
                      alignItems: 'flex-start'
                    }}>
                      <SubdirectoryArrowRightIcon
                        sx={{width: '18px', height: '18px'}}/>
                      <img
                        src={`data:image/svg+xml;base64,${selectedParentTopic.icon}`}
                        alt="icon"
                        style={{
                          width: '17px',
                          height: '17px',
                          marginRight: '8px'
                        }} // Ajusta el tamaño según sea necesario
                      />
                      <Typography
                        sx={{
                          fontFamily: 'Montserrat',
                          fontSize: '14px',
                          fontWeight: '500',
                          color: '#717171',
                          wordWrap: 'break-word', // Permitir múltiples líneas,
                          lineHeight: '17px',
                          letterSpacing: '0.0025em',
                        }}
                      >
                        {selectedParentTopic?.topic}
                      </Typography>
                    </Box>
                  )}
                </Grid>
                <Grid item sx={{width: dimensions.width - 360}}>
                  {keys.length > 1 && (
                    <CustomLegend
                      data={keys}
                      label={segmentationVariables.find(sv => sv.propName === referenceAggTopicSegment[policy.id])?.label}
                      width={dimensions.width - 200}
                      referenceKey={referenceKey}
                      updateReferenceKey={setReferenceKey}
                      colorPalette={currentColorScheme}
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <Box width="100%" flexGrow={1}>
            <Box sx={{width:'100%',height:'100%'}} flexGrow={1}>
              <ResponsiveBar
                data={barData.sort((a, b) => a[referenceKey] - b[referenceKey])}
                keys={referenceKeyOnTop(keys)}
                indexBy={indexBy}
                animate={true}
                motionStiffness={90}
                motionDamping={15}
                colorBy={keys.length > 1 ? 'id' : 'value'}
                width={dimensions.width}
                height={height - 40}
                margin={{
                  top: keys.length > 1 ? 50 : 50,
                  right: 25,
                  bottom: 0,
                  left: 250
                }}
                barComponent={(barProps) => (
                  <CustomBarComponent
                    {...barProps}
                    keys={keys}
                    selectedNodeData={selectedNodeData}
                    dimensions={dimensions}
                    updateSelectedNodeData={setSelectedNodeData}
                    selectedTopic={selectedTopic}
                    selectedParentNodeData={selectedParentNodeData}
                    updateSelectedParentNodeData={setSelectedParentNodeData}
                    setTooltipData={setTooltipData}
                  />
                )}
                padding={0.2}
                innerPadding={2}
                groupMode="grouped"
                layout="horizontal"
                valueScale={{type: 'linear'}}
                indexScale={{type: 'band', round: true}}
                colors={currentColorScheme}
                theme={theme}
                borderColor={{
                  from: 'color',
                  modifiers: [
                    ['darker', 1.6]
                  ]
                }}
                axisTop={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: `${keys.length === 1 ? t('bar_topic_representativity') : t('bar_segment_representativity')} (%)`,
                  legendPosition: 'middle',
                  legendOffset: -40,
                  truncateTickAt: 0,
                  format: (value) => `${value.toFixed(0)}%`
                }}
                axisBottom={keys?.length > 1 && graphScrollable ? {
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: 'Representativity (%)',
                  legendPosition: 'middle',
                  legendOffset: 40,
                  truncateTickAt: 0,
                  format: (value) => `${value.toFixed(1)}%`
                } : null}
                axisLeft={{
                  renderTick: (tick) => <CustomTick tick={tick}
                                                    maxLineLength={20}
                                                    topics={policy.topics}
                                                    selectedNodeData={selectedNodeData}
                                                    selectedTopic={selectedTopic}/>,
                }}
                enableGridX={false}
                enableGridY={false}
                labelSkipWidth={35}
                labelSkipHeight={16}
                label={(value) => `${value.value.toFixed(1)}%`}
              />
            </Box>
            {(!!tooltipData)&&(
              <Box
                sx={{
                  position: 'absolute',
                  bottom: '10px',
                  right: '10px',
                  width: '450px',
                  backgroundColor: 'transparent',
                  zIndex: 9000,
                  padding: '16px',
                  boxSizing: 'border-box',
                  animation: `slideInFromBelow 0.3s ease-out`,
                  '@keyframes slideInFromBelow': {
                    '0%': {
                      opacity: 0,
                      transform: 'translateY(100%)',
                    },
                    '100%': {
                      opacity: 1,
                      transform: 'translateY(0)',
                    },
                  },
                }}
              >
                <CustomTooltip bar={tooltipData.bar} isSegmented={tooltipData.isSegmented||referenceAggTopicSegment[policy.id]}/>
              </Box>
            )}
          </Box>
          {(keys.length > 1 && graphScrollable) && (
            <Box width="100%" display="flex" justifyContent="center" mt={2}
                 style={{margin: 0}}>
              <CustomLegend data={keys} width={dimensions.width - 200}
                            label={segmentationVariables.find(sv => sv.propName === referenceAggTopicSegment[policy.id])?.label}
                            referenceKey={referenceKey}
                            updateReferenceKey={setReferenceKey}
                            colorPalette={currentColorScheme}/>
            </Box>
          )}
        </>
      )}
    </Box>


  );

}

export default BarGraph;
