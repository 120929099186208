import VoicePlayer from "../../../../../common/VoicePlayer";
import {getContrastTonality} from "../../../../../../utils/colorPaletteUtils";
import * as React from "react";
import {SvgIcon} from "@mui/material";
import {InfoOutlined} from "@mui/icons-material";

export const CustomLayer = ({
                              nodes,
                              policy,
                              onClick,
                              drillDown,
                              hasAudio,
                              setTooltip
                            }) => {


  function getContrastColor(colorInput) {
    return getContrastTonality(colorInput) === "light" ? "white" : '#212121'
  }


  const renderIcon = (base64Icon, x, y, color = '#212121', nodeType) => {
    const iconStyles = {
      iconPath: {
        fill: color,
        transform: `translate(${x}px, ${y}px) scale(${['root', 'parent_topic'].includes(nodeType) ? '0.04' : '0.055'})`,
      },
      iconArrowPath: {
        fill: color,
        transform: `translate(${x - 30}px, ${y}px) scale(1)`,
      },
    }

    const iconPath = decodeBase64Icon(base64Icon);
    const randomId = generateRandomString(10);
    const iconArrowPath = ['M18.29 15.71l-4.58 4.58c-.39.39-1.03.39-1.42 0-.39-.39-.39-1.03 0-1.42L15.17 ' +
    '16H5c-.55 0-1-.45-1-1V5c0-.55.45-1 1-1s1 .45 1 1v9h9.17l-2.88-2.87c-.39-.39-.39-1.03 0-1.42.39-.39 1.03-.39 ' +
    '1.42 0l4.58 4.58c.39.39.39 1.03 0 1.42z']

    return (
      <>
        {nodeType === 'root' || nodeType === 'parent_topic' ? (
          <>
            <path
              d={iconArrowPath}
              style={iconStyles.iconArrowPath}
              width={'20px'}
              height={'20px'}
              key={`randomId-${randomId}-1`}
            />
            <path
              d={iconPath}
              style={iconStyles.iconPath}
              width={'20px'}
              height={'20px'}
              key={`randomId-${randomId}-2`}
            />
          </>
        ) : (
          <path
            d={iconPath}
            style={iconStyles.iconPath}
            cursor={"pointer"}
            width={'20px'}
            height={'20px'}
            key={`randomId-${randomId}-2`}
          />
        )}
      </>
    );
  };

  function splitIntoLines(str, maxWidth, fontSize, fontFamily) {
    const words = str.split(' ');
    const lines = [];
    let currentLine = words[0];


    for (let i = 1; i < words.length; i++) {
      if (textWidth(currentLine + ' ' + words[i], fontSize, fontFamily) < maxWidth) {
        currentLine += ' ' + words[i];
      } else {
        lines.push(currentLine);
        currentLine = words[i];
      }
    }
    lines.push(currentLine);
    return lines;
  }

  const decodeBase64Icon = (base64Icon) => {
    // Step 1: Decode the base64 string to a regular string.
    const svgString = atob(base64Icon);

    // Step 2: Create a new DOM parser.
    const parser = new DOMParser();
    const svgDoc = parser.parseFromString(svgString, 'image/svg+xml');

    // Step 3: Extract the SVG path strings. (Assuming there is only one path)
    const paths = svgDoc.querySelectorAll('path');
    // Now you have an array of path strings that you can use in your render function.
    return Array.from(paths).map(path => path.getAttribute('d'));
  };

  const textWidth = (text, fontSize, fontFamily) => {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    context.font = `${fontSize}px ${fontFamily}`;
    return context.measureText(text).width;
  };

  function generateRandomString(length) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  // const springs = useSprings(nodes.length, nodes.map((node, index) => {
  //   // Provide default values in case node.x or node.y are undefined
  //   const x = node.x || 0;
  //   const y = node.y || 0;
  //
  //   // Check if x or y are NaN and log or handle it
  //   if (isNaN(x) || isNaN(y)) {
  //     return {}; // Return an empty object or some default value
  //   }
  //
  //   if (isSearchLoading || hasAudio || !node.data.name) {
  //     return {
  //       opacity: 1, transform: `translate(${node.x || 0},${node.y || 0})`,
  //     }
  //   }
  //
  //   return {
  //     opacity: 1,
  //     transform: `translate(${x},${y})`,
  //     from: {opacity: 0, transform: `translate(${x - 20},${y - 20})`},
  //     config: {duration: 30},
  //     delay: (index) * 105
  //   };
  // }));
  return nodes.map((node, index) => {
    const textColor = getContrastColor(node.color);
    const randomId = generateRandomString(10);
    const subtitleText = policy?.parentPolicy?.name || policy.selectedClassificationSegment?.label || policy.selectedClassificationSegment?.propName || "";
    if (node.data.type !== 'root' && node.data.type !== 'parent_topic' && node.treeDepth > 0 && (node.width > 1 && node.height > 1)) {
      const lineHeight = 20; // Height of each line, adjust as needed
      const lines = splitIntoLines(node.data.name, node.width, lineHeight, 'Montserrat'); // Adjust font size and family accordingly
      const parentQuestionLines = splitIntoLines(subtitleText, node.width - 24, 12, 'Montserrat');
      const maxLines = Math.floor((node.height - 60) / (lineHeight)) - 3;
      let displayedLines = lines
      if (lines.length > maxLines && node.data.type === 'answer') {
        displayedLines = lines.slice(0, maxLines);
        displayedLines[maxLines - 1] = displayedLines[maxLines - 1] + "...";
      }
      let displayedParentQuestionLines = [];
      const maxParentQuestionLines = Math.floor((node.height - 50) / (14)) - displayedLines.length;
      if (maxParentQuestionLines > 0) {
        displayedParentQuestionLines = parentQuestionLines.slice(0, maxParentQuestionLines);
        if (parentQuestionLines.length !== displayedParentQuestionLines.length) {
          displayedParentQuestionLines[displayedParentQuestionLines.length - 1] = displayedParentQuestionLines[displayedParentQuestionLines.length - 1] + "...";
        }
      }

      const audio = node.data.audio;
      const totalTextHeight = (displayedLines.length * 18) + (displayedParentQuestionLines.length * 12);
      let textY = ((node.height / 2) - (totalTextHeight / 2));
      if (node.data.type === 'answer') {
        textY -= 20
      }

      const estimatedWidthPerChar = 7.6;  // Average width for Arial 16px
      const maxLineWidth = Math.max(...lines.map(line => line.length)) * estimatedWidthPerChar;
      const addIconTextNodes = (totalTextHeight + 70) < node.height && maxLineWidth < node.width;
      const iconFits = node.height > 40 && node.width > 40
      const addTextNode = (totalTextHeight + 10 <= node.height && maxLineWidth < node.width - 18) || node.data.type !== 'topic';
      const iconX = (node.width / 2) - 15;
      const iconY = addTextNode ? textY - 18 : (node.height / 2) - 15;
      const addIconNode = addIconTextNodes || (iconFits && !addTextNode);

      const contrastTonality = getContrastTonality(node.color);

      const adjustedTextY = textY + (addIconNode ? 25 : 0);
      return (
        <g
          key={`animated-graphics-node-${index}-${randomId}`}
          // style={springs[index]}  // Uncomment to apply the spring animation to the node
          transform={`translate(${node.x || 0},${node.y || 0})`}
          onClick={() => (onClick && node.data.type !== 'answer') ? onClick(node) : null}
        >
          {node.data.icon && addIconNode && renderIcon(node.data.icon, iconX, iconY, textColor)}
          {(!addTextNode && !addIconNode && (`( ${node.data.representativity}% )`.length * estimatedWidthPerChar + 5) < node.width) && (
            <text
              x={node.width / 2}
              y={(node.height / 2)}  // Adjust for centering
              fontSize="14px"
              fontWeight={'normal'}
              fontFamily="Montserrat"
              fontStyle={node.data.type === 'answer' ? 'italic' : 'normal'}
              textAnchor="middle"
              dominantBaseline="central"
              opacity={1}
              fill={textColor}
              cursor={node.data.type !== 'answer' ? "pointer" : "default"}
              key={`animated-text-node-${index}-${randomId}`}
            >
              ( {node.data.representativity}% )
            </text>
          )}
          {addTextNode && (
            <text
              x={node.width || 0 / 2}
              y={adjustedTextY}  // Adjust for centering
              fontSize="14px"
              fontWeight={'normal'}
              fontFamily="Montserrat"
              fontStyle={node.data.type === 'answer' ? 'italic' : 'normal'}
              textAnchor="middle"
              dominantBaseline="central"
              opacity={1}
              fill={textColor}
              cursor={node.data.type !== 'answer' ? "pointer" : "default"}
              key={`animated-text-node-${index}-${randomId}`}
            >
              {displayedLines.map((line, i) => {
                const isFirstLine = i === 0;
                const isLastLine = i === displayedLines.length - 1;
                let formattedLine = line;

                if (isFirstLine) formattedLine = `"${formattedLine}`;
                if (isLastLine) formattedLine = `${formattedLine}"`;

                return (
                  <tspan x={(node.width || 0) / 2} dy={i === 0 ? 0 : 18}
                         key={`text-node-${index}-Line-${i}_${randomId}_${node.id}`}
                         style={{lineHeight: '18px'}}>
                    {node.data.type === 'answer' ? formattedLine : line}
                  </tspan>
                )
              })}
            </text>)}
          {(node.data.type === 'topic' && !drillDown && (!!policy?.parentPolicy || !!policy.selectedClassificationSegment) && displayedParentQuestionLines?.length > 0 && addTextNode) && (
            <>
              <svg
                x={Math.max(((node.width || 0) / 2) - ((displayedParentQuestionLines[0].length * estimatedWidthPerChar) / 2) - (displayedParentQuestionLines.length > 1 ? 5 : 0), 5)}
                y={adjustedTextY + (displayedLines.length * 20)} width="12px"
                height="12px" viewBox="0 0 14 14"
                xmlns="http://www.w3.org/2000/svg"
                key={`parent-question-icon-node-${index}-${randomId}`}>
                <path
                  d="M5.83333 1.33325H11.1667V5.99992H9.16667V7.33325H11.1667H12.5V8.66659V9.99992H14.5V14.6666H9.16667V9.99992H11.1667V8.66659L9.16667 8.66658H7.83333L5.83333 8.66659V9.99992H7.83333V14.6666H2.5V9.99992H4.5V8.66659V7.33325H5.83333H7.83333V5.99992H5.83333V1.33325Z"
                  fill={textColor}
                  key={`parent-question-icon-path-node-${index}-${randomId}`}
                />
              </svg>
              {(addTextNode) && (
                <text
                  x={((node.width || 0) / 2) + (displayedParentQuestionLines.length > 1 ? 5 : 0)}
                  y={adjustedTextY + (displayedLines.length * 20) + 5}
                  width={node.width}
                  fontSize="10px"
                  fontWeight={'normal'}
                  fontFamily="Montserrat"
                  textAnchor="middle"
                  dominantBaseline="central"
                  opacity={1}
                  fill={textColor}
                  key={`parent-question-node-${index}-${randomId}`}
                >
                  {displayedParentQuestionLines.map((line, i) => {
                    return (
                      <>
                        <tspan x={((node.width || 0) / 2)} dy={i === 0 ? 0 : 12}
                               key={`${i}-parent-question-node=${index}-${node.x}${node.y}-${randomId}`}>
                          {line}
                        </tspan>
                      </>
                    )
                  })}
                </text>
              )}
            </>
          )
          }
          {
            (node.data.type === 'answer' && audio) && (
              <foreignObject width={node.width - 16} height={35} x={16}
                             y={node.height - 50}
                             key={`foreing-object-voice-container-${index}-${randomId}`}>
                <VoicePlayer audio={audio}
                             audioKey={`answer-audio-${index}-${randomId}`}
                             theme={contrastTonality || "dark"}
                             key={`voice-player-${index}-${randomId}`}/>
              </foreignObject>
            )
          }
          {(node.data.hasSubtopics && node.height > 40) && (
            <foreignObject x={(node.width || 0) - 50} y={4} width={24}
                           height={24}
                           key={`subtopic-indicator-${index}-${randomId}`}
                           style={{
                             opacity: 0.8,
                           }}
            >
              <SvgIcon sx={{width: '16px', height: '16px'}}
                       key={`subtopic-indicator-svg-${index}-${randomId}-${node.x}`}>
                <path
                  d="m14 4 2.29 2.29-2.88 2.88 1.42 1.42 2.88-2.88L20 10V4zm-4 0H4v6l2.29-2.29 4.71 4.7V20h2v-8.41l-5.29-5.3z"
                  fill={textColor}
                  transform="rotate(180 12 12)"
                  key={`subtopic-indicator-svg-path-${index}-${randomId}`}
                />
              </SvgIcon>
            </foreignObject>
          )}
          {(node.data.type !== 'answer' && node.data.type !== 'root' && node.data.type !== 'parent_topic' && node.width > 26 && node.height > 26) && (
            <foreignObject x={(node.width || 0) - 25} y={4} width={24}
                           height={24}
                           key={`info-indicator-${index}-${randomId}`}
                           onClick={(evt) => {
                             evt.stopPropagation();
                             evt.preventDefault();
                             if (setTooltip && typeof setTooltip === 'function') {
                               setTooltip({
                                 node: node,
                                 x: node.x + (node.width - 25),
                                 y: node.y,
                               });
                             }
                           }}>
              <SvgIcon sx={{
                width: '16px',
                height: '16px',
                cursor: 'pointer',
                opacity: 0.8,
                '&:hover': {opacity: 1}
              }} key={`info-indicator-svg-${index}-${randomId}`}>
                <InfoOutlined sx={{color: textColor}}/>
              </SvgIcon>
            </foreignObject>
          )}
        </g>
      );
    } else if ((node.width > 1 && node.height > 1)) {
      let text = node.data.rootText || node.data.name;
      let icon = node.data.icon
      let nodeType = node.data.type
      let yPadding = 5;
      let xPadding = 0;
      if (node.data.rootType) {
        switch (node.data.rootType) {
          case 'l1':
            yPadding = -35;
            xPadding = 80;
            break;
          case 'l2':
            yPadding = -75;
            xPadding = 130;
            break;
          default:
            yPadding = 5;
            xPadding = 0;
        }
      }
      const rootTextX = (node.x || 0) + 40 + xPadding;
      return (
        <g
          key={`animated-g-root-${node.data.rootType}-${index}-${randomId}`}
          // style={springs[index]}
          transform={`translate(${node.x || 0},${node.y || 0})`}
          onClick={() => (onClick && node.data.type !== 'answer') ? onClick(node) : null}
        >
          {icon && renderIcon(icon, (node.x || 0) + xPadding, (node.y || 0) + yPadding - 2, textColor, nodeType)}
          <text
            key={`root-text-node-${node.data.rootType}-${index}-${randomId}`}
            x={rootTextX}  // A little padding from the left edge
            y={(node.y || 0) + 3 + yPadding}  // Adjust so text doesn't overlap with the top edge
            fontSize="14px"  // Set the desired font size
            fontFamily="Montserrat"  // Set the desired font size
            fontWeight={'500'}
            dominantBaseline="hanging"  // Aligns the top of the text with the y position
            fill={textColor}  // You can use the labelTextColor prop's logic here if needed
            cursor={node.data.type !== 'answer' ? "pointer" : "default"}
          >
            {text}
          </text>
          {(node.data.rootType === 'l0' && !!policy.parentPolicy) && (
            <text
              key={`root-subtitle-node-${node.data.rootType}-${index}-${randomId}`}
              x={rootTextX + textWidth(text, 16, 'Montserrat') + 8}
              y={(node.y || 0) + 7 + yPadding}
              fontSize="12px"
              fontFamily="Montserrat"
              fontWeight={'normal'}
              dominantBaseline="hanging"
              fill={textColor}
            >
              ({subtitleText})
            </text>
          )}
          {((node.data.rootType === 'l0' || node.data.rootType === 'l1' || node.data.rootType === 'l2') && node.width > 26 && node.height > 26) && (
            <foreignObject
              x={rootTextX + textWidth(text, 14, 'Montserrat') + (node.data.rootType === 'l1' ? 15 : 10)}
              y={4}
              width={24}
              height={24}
              key={`info-indicator-${index}-${randomId}`}
              onClick={(evt) => {
                evt.stopPropagation();
                evt.preventDefault();
                if (setTooltip && typeof setTooltip === 'function') {
                  setTooltip({
                    node: node,
                    x: rootTextX + textWidth(text, 16, 'Montserrat') + (node.data.rootType === 'l1' ? 0 : 5),
                    y: node.y,
                  });
                }
              }}>
              <SvgIcon sx={{
                width: '16px',
                height: '16px',
                cursor: 'pointer',
                opacity: 0.8,
                '&:hover': {opacity: 1}
              }} key={`info-indicator-svg-${index}-${randomId}`}>
                <InfoOutlined sx={{color: textColor}}/>
              </SvgIcon>
            </foreignObject>
          )}
        </g>
      );
    }
    return null;
  });
}
