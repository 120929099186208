import {
  Badge,
  Box,
  Grid,
  IconButton,
  Modal,
  Popover,
  SvgIcon,
  Tooltip,
  Typography
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/DeleteOutline";
import * as React from "react";
import {useRef, useState} from "react";
import {MuiSplitIcon, MuiSplitIconButton} from "./styles/topic";
import {
  useTranslation
} from "../../../../../../../providers/TranslationProvider";
import {ClassificationSample} from "./ClassificationSample";
import CloseIcon from "@mui/icons-material/HighlightOffOutlined";
import {useSnackbar} from "notistack";
import {useLoading} from "../../../../../../../providers/LoadingProvider";
import axios from "../../../../../../../../api/axios/axiosInstance";
import apiPaths from "../../../../../../../../api/apiPaths";
import {status200} from "../../../../../../../../api/status.utils";
import {
  MuiOutlinedButton,
  MuiTextButton,
  MuiTopicAmountSelector,
  MuiTopicAmountSelectorTypography
} from "./styles/topicExtractionControls";

export const TopicActions = ({
                               topic,
                               index,
                               isRootTopic,
                               hierarchyMode,
                               rootClassificationVariable,
                               handleTopicChange,
                               handleDelete,
                               answerVariable,
                               collection,
                               dataRegion,
                               weightVariable
                             }) => {
  const [openModal, setOpenModal] = useState(false);
  const subtopicModalAnchor = useRef(null);
  const [openSubtopicModal, setOpenSubtopicModal] = useState(false);
  const {t} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();
  const [amountSubtopics, setAmountSubtopics] = useState(3)
  const {
    setIsLoading,
    setTitle
  } = useLoading();

  const {lng} = useTranslation();

  const handleInferSubTopics = async () => {
    if (!collection || !answerVariable || !topic) {
      return null;
    }
    setIsLoading(true);
    setTitle(`Extracting subtopics for "${topic.topic}"...`)
    const payload = {
      collection_name: collection,
      answers_column: answerVariable,
      max_topics: amountSubtopics,
      weight: weightVariable,
      target_lang: lng,
      parent_topic: topic.topic,
      requestId: null,
      region: dataRegion || "US",
      parentClassificationVariable: rootClassificationVariable
    }
    try {
      let response = await axios.post(apiPaths.infer_subtopics, payload, status200);
      setIsLoading(false);
      let data = response.data;
      let subtopics = null
      if (data && data.topics) {
        subtopics = data.topics.map(subtopic => {
          let st = {...subtopic};
          st.isParent = false;
          st.parent = topic;
          st.representativity = -1;
          return st;
        });
        successToast("Subtopics extracted...");
      } else {
        errorToast("Error extracting subtopics")
      }
      if (subtopics?.length > 0) {
        let localParent = {...topic};
        localParent.representativity = -1;
        localParent.subtopics = subtopics;
        handleTopicChange(localParent, index);
        subtopicModalAnchor.current = null;
        setOpenSubtopicModal(false);
      }

      return subtopics;
    } catch (error) {
      console.log(error)
      errorToast(`Error extracting subtopics for topic: ${topic}`);
    }
    // await handleSaveScheme();
    setIsLoading(false);
    return null;
  }

  const errorToast = (msg) => {
    enqueueSnackbar(msg, {variant: 'error'})
  }

  const successToast = (msg) => {
    enqueueSnackbar(msg, {variant: 'success'})
  }

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const tooltipText = () => {
    return `Quotes referencing to ${topic.topic}: ${(topic.count || 0)} (${(topic.representativity || 0).toFixed(0)}%)`;
  }

  const getTopicExtractionDescriptionTxt = () => {
    const normal = t('topic_extraction_caption_normal') || "Specify the amount of topics you want us to extract from the interview responses."
    const segment = t('topic_extraction_caption_segments') || "Specify the amount of topics you’d like to explore within each category of \"{segment_name}\". This will help you discover contextualized and more specific insights for each category in the segment."
    const rootQuestion = t('topic_extraction_caption_root_question') || "Specify the amount of topics you'd like to explore for each group of responses to the root question. This will tightly link the follow-up responses to the main topics of the original questions, ensuring context is maintained."
    if (hierarchyMode === "None") {
      return normal;
    } else if (hierarchyMode === "Segment") {
      return segment.replace("{segment_name}", 'segment');
    } else if (hierarchyMode === "Question") {
      return rootQuestion
    }
  }

  const SubtopicInferenceControl = () => {
    if (isRootTopic) {
      return (
        <Badge badgeContent={topic.subtopics?.length || 0}
               color="primary"
               sx={{
                 '.MuiBadge-badge': {
                   backgroundColor: 'primary',  // Make the badge background transparent
                   display: 'flex',
                   color: '#FFFFFF',
                   alignItems: 'center',
                   justifyContent: 'center',
                   fontSize: '10px',
                   minWidth: '16px',
                   minHeight: '16px',
                   height: '16px',
                   borderRadius: '8px',
                   fontWeight: 600,
                   letterSpacing: '0.015em',
                   fontFamily: 'Montserrat',
                   padding: 0
                 },
               }}
        >
          <MuiSplitIconButton
            onClick={(evt) => {
              evt.stopPropagation();
              evt.preventDefault();
              setOpenSubtopicModal(true);
            }}>
            <MuiSplitIcon/>
          </MuiSplitIconButton>
        </Badge>
      )
    }
    return null;
  }

  return (
    <>
      <Grid container direction='row' alignContent='center' alignItems='center'
            justifyContent='flex-end'>
        <Grid item>
          <Tooltip
            title={(
              <Typography
                sx={{
                  fontFamily: 'Montserrat',
                  fontSize: '10px',
                  fontWeight: 400,
                  fontStyle: 'normal'
                }}
              >
                {tooltipText()}
              </Typography>
            )}
            arrow
            placement={"top"}
          >
            <Badge
              badgeContent={
                <span style={{
                  color: '#FFFFFF',
                  fontSize: isRootTopic ? '16px' : '11px',
                  fontWeight: 600,
                  letterSpacing: '0.015em',
                  fontFamily: 'Montserrat',
                  width: 'fit-content',
                  whiteSpace: 'nowrap', // Prevents line breaks
                  textAlign: 'center'   // Optional, for better centering
                }}>
                    {topic.representativity >= 0 ? (topic.representativity || 0).toFixed(0) + "%" : "?%"}
                  </span>
              }
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              sx={{
                '.MuiBadge-badge': {
                  backgroundColor: 'transparent',  // Make the badge background transparent
                  width: '30px',                       // Adjust the size of the icon
                  height: '20px',
                  mt: '21px',
                  ml: isRootTopic ? '35px' : '30px',
                  borderRadius: '50%',             // Make it a circular badge
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  cursor: topic.representativity >= 0 ? 'pointer' : 'default',
                }
              }}
              onClick={topic.representativity >= 0 ? handleOpenModal : () => null}
            >
              <IconButton
                onClick={topic.representativity >= 0 ? handleOpenModal : () => null}
                sx={{
                  '&.MuiIconButton-root:hover': {
                    backgroundColor: 'transparent'
                  },
                  cursor: topic.representativity >= 0 ? 'pointer' : 'default',
                }}>
                <SvgIcon
                  sx={{width: isRootTopic ? '50px' : '40px', height: '32px'}}>
                  <svg width={isRootTopic ? '50px' : '40px'} height="32"
                       viewBox={`0 0 ${isRootTopic ? '50' : '40'} 32`}
                       fill="none"
                       xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M47.2 0L2.8 7.43866e-05C1.26 7.43866e-05 0 1.26007 0 2.80007V32L5.59961 26L47.1996 26C48.7396 26 49.9996 24.74 49.9996 23.2L50 2.8C50 1.26 48.74 0 47.2 0Z"
                      fill="#9E9E9E"/>
                  </svg>
                </SvgIcon>
              </IconButton>
            </Badge>
          </Tooltip>
        </Grid>
        {(isRootTopic) && (
          <Grid ref={subtopicModalAnchor} item sx={{ml: '9px'}}>
            <SubtopicInferenceControl/>
          </Grid>)}
        {((isRootTopic && hierarchyMode === 'None') || (!isRootTopic)) && (
          <Grid item sx={{ml: '4px'}}>
            <IconButton
              color="error"
              sx={{
                '&:hover': {backgroundColor: "#FFCFD2"},
                width: '26px',
                height: '26px',
                border: '1px solid #FFCFD2',
                borderRadius: '18px',
              }}
              onClick={() => handleDelete(index)}
            >
              <DeleteIcon
                sx={{color: '#F63B2B', width: '16px', height: '16px'}}/>
            </IconButton>
          </Grid>
        )}
        <Modal
          open={openModal}
          onClose={handleCloseModal}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
        >
          <Box sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '595px',
            border: '1px solid #FFFFFF',
            backgroundColor: '#FFFFFF',
            borderRadius: '28px',
            p: 4,
          }}>
            <Grid container direction='column' sx={{width: '100%'}}>
              <Grid container item direction='row' alignContent='center'
                    wrap={'nowrap'} sx={{width: '100%'}}>
                <Grid item xs={true}>
                  <Typography id="modal-title" sx={{
                    fontFamily: 'Raleway',
                    fontSize: '25px',
                    fontWeight: '400',
                    lineHeight: '29px',
                    color: '#212121',
                  }}>
                    {t('quotes_related_to')}
                  </Typography>
                </Grid>
                <Grid container item xs={'auto'}>
                  <IconButton
                    aria-label="close"
                    onClick={handleCloseModal}
                    sx={{
                      width: '15px',
                      height: '15px'
                    }}
                  >
                    <CloseIcon sx={{width: '15px', height: '15px'}}/>
                  </IconButton>
                </Grid>
              </Grid>
              <Grid item sx={{mt: '10px'}}>
                <Typography sx={{
                  fontFamily: 'Montserrat',
                  fontSize: '16px',
                  fontWeight: '400',
                  lineHeight: '20px',
                  color: '#616161',
                }}>
                  "{topic.topic}"
                </Typography>
              </Grid>
              <Grid container item sx={{mt: '24px'}}>
                {openModal && (
                  <ClassificationSample answerVariable={answerVariable}
                                        topic={topic} collection={collection}
                                        dataRegion={dataRegion}/>
                )}
              </Grid>
            </Grid>
          </Box>
        </Modal>
        <Popover
          anchorEl={subtopicModalAnchor.current}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          sx={{
            mt: '10px',
            '& .MuiPaper-root': {
              borderRadius: '28px'
            }
          }}
          open={openSubtopicModal}
          onClose={() => {
            setOpenSubtopicModal(false);
          }}
          disablePortal
        >
          <Box sx={{padding: '24px', width: '512px'}}
               justifyContent={'center'}>
            <Typography sx={{
              fontFamily: 'Raleway',
              fontSize: '25px',
              lineHeight: '29px',
              fontWeight: '400',
              color: '#212121',
            }}>
              {hierarchyMode !== 'None' ? t('discover_topics') : t('discover_subtopics')}
            </Typography>
            <Typography sx={{
              fontFamily: 'Montserrat',
              fontSize: '16px',
              lineHeight: '20px',
              letterSpacing: '0.005em',
              fontWeight: 400,
              color: '#757575',
              mt: '10px'
            }}>{getTopicExtractionDescriptionTxt()}</Typography>
            <Grid container direction="column" sx={{mt: '5px'}}>
              <Grid container item direction='row' columnGap={'16px'}
                    alignContent={'center'}>
                {Array(8).fill(1).map((_, index) => {
                  const value = index + 3;
                  return (
                    <MuiTopicAmountSelector
                      container
                      item
                      alignContent='center'
                      justifyContent='center'
                      key={`amount-${value}`}
                      isSelected={amountSubtopics === value}
                      sx={{mt: '16px'}}
                      onClick={() => setAmountSubtopics(value)}
                    >
                      <MuiTopicAmountSelectorTypography
                        isSelected={amountSubtopics === value}
                      >
                        {value}
                      </MuiTopicAmountSelectorTypography>
                    </MuiTopicAmountSelector>
                  )
                })}
              </Grid>
              <Grid container item direction={'row'} columnGap={'8px'}
                    sx={{width: '100%', mt: '24px', pb: '24px'}}
                    justifyContent={'flex-end'}>
                <MuiTextButton variant='text' sx={{
                  height: '37px',
                  borderRadius: '20px',
                  color: 'primary',
                }}
                               onClick={() => setOpenSubtopicModal(false)}>{t('cancel_btn')}</MuiTextButton>
                <MuiOutlinedButton variant="outlined"
                                   onClick={handleInferSubTopics}>
                  {t('ok_btn')}
                </MuiOutlinedButton>
              </Grid>
            </Grid>
          </Box>
        </Popover>
      </Grid>

    </>
  )
}
