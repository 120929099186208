import {Grid, Typography} from "@mui/material";
import {HintContainer} from "./styles/qaHint";

export const QAHint = ({icon, title, question, setQuestion}) => {

  return (
    <HintContainer
      onClick={(evt) => {
        evt.stopPropagation();
        setQuestion(question);
      }}
    >
      <Grid container direction='row' alignContent={'center'}
            sx={{height: '100%'}} columnGap={'8px'}>
        {(!!icon) && (
          <Grid item xs={'auto'} sx={{height: '100%'}} alignContent={'center'}
                justifyContent={'center'}>
            {icon}
          </Grid>
        )}
        <Grid container item xs={true} direction={'column'} rowGap={'4px'}>
          <Typography
            sx={{
              fontFamily: 'Montserrat',
              fontSize: '14px',
              fontWeight: 600,
              fontStyle: 'normal',
              textAlign: 'left',
              letterSpacing: '0.005em',
              lineHeight: '17px',
              color: '#424242',
              cursor: 'pointer'
            }}
            onClick={() => {
              setQuestion(question);
            }}
          >{title}</Typography>
          <Typography
            sx={{
              fontFamily: 'Montserrat',
              fontSize: '12px',
              fontStyle: 'normal',
              fontWeight: 400,
              textAlign: 'left',
              color: '#616161',
              lineHeight: '15px',
              letterSpacing: '0.005em',
              cursor: 'pointer'
            }}
            onClick={() => {
              setQuestion(question);
            }}
          >{question}</Typography>
        </Grid>
      </Grid>
    </HintContainer>
  );
}
