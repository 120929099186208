import {Box, Button, Paper, StepIcon, styled} from "@mui/material";

export const MuiBoxContainer = styled(Box)(({ theme }) => ({
  width: '100% !important',
  maxWidth: '1058px !important',
  marginTop:'17px',
  borderRadius:'20px'
}));

export const MuiGridContainerStepper = styled(Box)(({ theme }) => ({
  width:'100%',
  maxWidth:'100%'
}));

export const StepperContainer = styled(Box)(({ theme }) => ({
  width:'100%',
  maxWidth:'100%'
}));

export const MuiPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary
}));

export const MuiCustomStepIcon = styled(StepIcon)(({ theme }) => ({
  color: '#E0E0E0',
  '&.MuiStepIcon-root.Mui-completed': {
    color: theme.palette.primary.main,
  },
  '&.MuiStepIcon-root.Mui-active': {
    color: theme.palette.primary.main,
  },
}));

export const MuiBtnNext = styled(Button)(({ theme }) => ({
  padding:'10px 24px 10px 24px',
  borderRadius: '20px',
  height:'37px',
  fontFamily:'Montserrat'
}));

export const labelStepStyle = {
  cursor: 'pointer',
  textTransform:'uppercase',
  fontFamily:'Montserrat',
  fontWeight:500,
  fontSize:12,
}
