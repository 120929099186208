import * as React from 'react';
import {
  Autocomplete,
  Button as MuiButton,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  TextField,
  ThemeProvider,
  Typography
} from '@mui/material';
import * as yup from 'yup';
import {Controller, FormProvider, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {useLocation} from 'react-router-dom';

import Modal from 'components/common/Modal';
import Toast from 'components/common/Toast';
import {SelectField} from 'components/common/ReactHooksFormFields';
import Button from 'components/common/Button';

import {useTranslation} from 'components/providers/TranslationProvider';
import themeCrowdView from 'theme/crowdView';
import useUpdateUser from 'api/hooks/useUpdateUser';
import useCreateUser from 'api/hooks/useCreateUser';
import useOrganizations from 'api/hooks/useOrganizations';
import {useAuth} from 'components/providers/AuthProvider';
import {
  buttonActionStyle,
  MuiContainer,
  MuiTexInput,
  optionLabelStyle,
  selectRoleStyle
} from '../styles/formModal';
import {Visibility, VisibilityOff} from '@mui/icons-material';
import {useTheme} from '../../../providers/CustomThemeProvider';
import useOrganization from '../../../../api/hooks/useOrganization';
import {buttonCancelStyle} from '../scenarios/styles/formModal';

const selectUserTypeInputValues = [
  {
    value: 'level_1',
    label: 'Level 1',
  },
  {
    value: 'level_2',
    label: 'Level 2',
  },
  {
    value: 'level_3',
    label: 'Level 3',
  },
  {
    value: 'admin',
    label: 'Admin',
  },
];

const FormModal = ({ user, action, open, onClose }) => {
  const [openToast, setOpenToast] = React.useState(false);
  const [error, setError] = React.useState('');
  const [inputValue, setInputValue] = React.useState('');
  const [disabledSave, setDisabledSave] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [selectedOptions, setSelectedOptions] = React.useState([]);

  const { t } = useTranslation();
  const { data: organizations, isLoading } = useOrganizations();
  const { user: authUser } = useAuth();

  const [allOptions] = React.useState(
    organizations ? organizations.filter(option =>
             !selectedOptions.find(selected => selected.id === option._id))
                             .map(o => ({ id: o._id, name: o.name })) : []);

  const {theme} = useTheme();
  const location = useLocation();
  const orgId = location.pathname.split('/')[2];
  const { data: currentOrg } = useOrganization(orgId)

  const schema = yup.object().shape({
    // TODO: Traduction
    name: yup
      .string()
      .min(4, 'min_4_characters_field')
      .max(50, 'This field can not exceed 50 characters')
      .required('required'),
    lastname: yup
      .string()
      .min(4, 'min_4_characters_field')
      .max(50, 'This field can not exceed 50 characters')
      .required('required'),
    email: yup.string().when([], {
      is: () => action === 'create',
      then: () => yup.string().email('email_required').required('email_required'),
      otherwise: () => yup.string().notRequired(),
    }),
    password: yup.string()
      .when([], {
        is: () => action === 'create',
        then: () => yup.string().min(4, 'min_4_characters').matches(/^(?=.*[A-Z])(?=.*[0-9])/,
          'match_password_validation').required('required'),
        otherwise: () => yup.string().notRequired(),
      }),
    userType: yup.string().required('Required'),
  });

  const {
    mutateAsync: updateUser,
    status: updateStatus,
    error: updateError,
    isLoading: updateIsLoading,
  } = useUpdateUser();
  const {
    mutateAsync: createUser,
    status: createStatus,
    error: createError,
    isLoading: createIsLoading,
  } = useCreateUser();

  // eslint-disable-next-line
  const getDefaultValues = () => {
    return user
      ? {
          name: user.name,
          lastname: user.lastname,
          email: user.email,
          userType: user.type,
        }
      : {};
  };

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: getDefaultValues(),
    mode: 'all',
  });

  const {
    handleSubmit,
    formState: { errors, isValid, dirtyFields },
    control,
    setValue,
    getValues,
    watch,
  } = methods;

  const userType = watch('userType', '');

  const handleCloseToast = () => {
    setOpenToast(false);
  };

  const createOrUpdateUser = (data) => {
    let adminRight;
    let orgsUserLevel;
    if(data.userType === 'admin'){
      adminRight = data.organizations ? data.organizations.map((o) => o.id) : [];
      orgsUserLevel = [];
    } else {
      adminRight = [];
      orgsUserLevel = data.organizations ? data.organizations.map((o) => o.id) : [];
    }

    if(action === 'create'){
      const userToCreate = {
        user: {
          id: getRandomId(),
          name: data.name,
          lastname: data.lastname,
          email: data.email,
          password: data.password,
          type: data.userType,
          org_id: orgId,
          admin_rights: adminRight,
          orgs_user_level: orgsUserLevel,
        },
        user_id: location.pathname.split('/')[2],
      };
      createUser(userToCreate);
    }
    else {
      const userToUpdate = {
        ...user,
        name: data.name,
        lastname: data.lastname,
        type: data.userType,
        admin_rights: adminRight,
        org_id: orgId,
        user_id: user._id,
        orgs_user_level: orgsUserLevel,
      };
      updateUser(userToUpdate);
    }
  }

  const getRandomId = () => Date.now().toString(36) + Math.random().toString(36).substr(2);

  const onSubmit = handleSubmit((data) => (createOrUpdateUser(data)));

  const handlePasswordVisibility = () => setShowPassword(!showPassword);

  React.useEffect(() => {
    if (updateStatus === 'success') {
      onClose();
    }
    if (updateStatus === 'error') {
      console.error(updateError);
      setError(t(updateError.data.error));
      setOpenToast(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateStatus]);

  React.useEffect(() => {
    if (createStatus === 'success') {
      onClose();
    }
    if (createStatus === 'error') {
      setError(t(createError.data.error));
      setOpenToast(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createStatus]);

  React.useEffect(() => {
    if (user?.type === 'admin' && organizations) {
      const orgsAdmin = organizations
        .filter((o) => user.myOrgs.includes(o.name))
        .map((o) => ({ name: o.name, id: o._id }));
      setValue('organizations', orgsAdmin);
      setSelectedOptions(getValues('organizations'))
    }
    else if (user !== null && user?.myOrgsLevel.length !== 0 && organizations) {
      const orgsLevel = organizations
        .filter((o) => user?.myOrgsLevel.includes(o.name))
        .map((o) => ({ name: o.name, id: o._id }));
      setValue('organizations', orgsLevel);
      setSelectedOptions(getValues('organizations'))
    }
  }, [user, organizations, setValue]);

  React.useEffect(() => {
    const handleDisabledSave = () => {
      if (action === 'create') {
        return !isValid || updateIsLoading || createIsLoading || isLoading;
      } else return Object.keys(dirtyFields).length === 0 || updateIsLoading || createIsLoading || isLoading;
    };
    setDisabledSave(handleDisabledSave());
  }, [action, isValid, updateIsLoading, createIsLoading, isLoading, Object.keys(dirtyFields).length]);

  const formUser = (
    <MuiContainer display='flex'>
      <Toast
        message={error}
        handleClose={handleCloseToast}
        severity='error'
        horizontal='center'
        vertical='top'
        open={openToast}
      />
      <ThemeProvider theme={themeCrowdView}>
        <Grid item xs={12}>
          <MuiTexInput
            autoFocus
            name='name'
            margin='dense'
            type='text'
            label={t('user_name')}
            variant='outlined'
            fullWidth
            error={t(errors.name?.message)}
          />
        </Grid>
        <Grid item xs={12}>
          <MuiTexInput
            name='lastname'
            margin='dense'
            type='text'
            label={t('lastname')}
            variant='outlined'
            fullWidth
            error={t(errors.lastname?.message)}
          />
        </Grid>
        {action === 'create' && (
          <>
            <Grid item xs={12}>
              <MuiTexInput
                variant='outlined'
                fullWidth
                label={t('email')}
                name='email'
                autoComplete='email'
                error={t(errors.email?.message)}
              />
            </Grid>
            <Grid item xs={12}>
              <MuiTexInput
                variant='outlined'
                fullWidth
                name='password'
                type={showPassword ? 'text' : 'password'}
                autoComplete='current-password'
                error={t(errors.password?.message)}
                label={t('password')}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handlePasswordVisibility}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          <SelectField
            sx={selectRoleStyle}
            name='userType'
            select
            label={t('user_type_label')}
            variant='outlined'
            value={userType}
            error={t(errors.userType?.message)}
            fullWidth
          >
            {selectUserTypeInputValues
              .filter((o) => (authUser.type === 'admin' ? o.value !== 'admin' : o.value))
              .map((o) => (
                <MenuItem key={o.value} value={o.value}>
                  <Typography  variant='body1' fontFamily='Montserrat' fontSize={16}  letterSpacing={0.5}>
                    {o.label}
                  </Typography>
                </MenuItem>
              ))}
          </SelectField>
        </Grid>
        <Grid item xs={12}>
          <Controller
            render={() => (
              <Autocomplete
                id='organizations-id'
                multiple
                disableCloseOnSelect
                options={allOptions}
                getOptionLabel={(option) => option.name}
                ListboxProps={{sx: optionLabelStyle}}
                getOptionSelected={(option, value) => option.id === value.id}
                getOptionDisabled={(option) => option.name === currentOrg.name}
                disabled={isLoading}
                filterSelectedOptions
                sx={{
                  width:'400px'
                }}
                onChange={(e, value) => {
                  setValue('organizations', value);
                  setSelectedOptions(value)
                }}
                value={selectedOptions}
                inputValue={inputValue}
                onInputChange={(event, newInputValue) => {
                  setInputValue(newInputValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    colorSecondary={theme.palette.secondary.main}
                    label={userType === 'admin' ? t('select_organization_admin_label') : t('select_organization_label')}
                    name='organizations'
                    variant='outlined'
                    fullWidth
                    sx={{
                      '& .MuiInputBase-root': {
                        width: '400px',
                      },
                      '& .MuiSvgIcon-root': {
                        color: '#143440 !important',
                        backgroundColor: 'none !important'
                      },
                      '& .MuiAutocomplete-clearIndicator': {
                        backgroundColor: 'transparent !important',
                        marginRight:'4px'
                      },
                      '& .MuiInputLabel-root': {
                        fontSize: '16px',
                        fontFamily:'Montserrat',
                        letterSpacing:'0.5px'
                      },
                      '& .MuiChip-label': {
                        fontFamily: 'Montserrat',
                        fontWeight: 400,
                        fontSize: '14px',
                        color: '#254957',
                      },
                      '& input': {
                        fontSize: '16px',
                        fontFamily:'Montserrat',
                      },
                      '& legend': {
                        fontSize: '14px',
                      },
                    }}
                  />
                )}
              />
            )}
            control={control}
            name='organizations'
            defaultValue={[]}
          />
        </Grid>
      </ThemeProvider>
    </MuiContainer>
  );

  const actions = (
    <>
      <MuiButton onClick={onClose} variant='text' sx={buttonCancelStyle}>
        <Typography  fontFamily='Montserrat' fontSize={14} letterSpacing={0.25}>
          {t('cancel_btn')}
        </Typography>
      </MuiButton>
      <Button
        onClick={onSubmit}
        style={{...buttonActionStyle, width:'150px'}}
        variant='outlined'
        loading={updateIsLoading || createIsLoading || isLoading}
        disabled={disabledSave}
      >
        <Typography  fontFamily='Montserrat' fontSize={14}  letterSpacing={0.25}>
          {t('save_changes')}
        </Typography>
      </Button>
    </>
  );

  return (
    <FormProvider {...methods}>
      <form>
        <Modal
          open={open}
          onClose={onClose}
          // TODO: Traduction t('update') and t('create_user')
          title={action === 'update' ? `${'Update'} ${user?.name}` : `${'New User'}`}
          actions={actions}
        >
          {formUser}
        </Modal>
      </form>
    </FormProvider>
  );
};

export default FormModal;
