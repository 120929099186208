import * as React from 'react';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
// import { ThemeProvider } from '@material-ui/core/styles';
import * as yup from 'yup';
import {FormProvider, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';

import Modal from 'components/common/Modal';
import Toast from 'components/common/Toast';
import {TextField} from 'components/common/ReactHooksFormFields';
import Button from 'components/common/Button';

import {useTranslation} from 'components/providers/TranslationProvider';
import themeCrowdView from 'theme/crowdView';
import useUpdateTranslation from 'api/hooks/useUpdateTranslation';
import useCreateTranslation from 'api/hooks/useCreateTranslation';
import {MuiContainer, MuiGridInputText} from './styles/formModal';

const FormModal = ({ translation, action, open, onClose }) => {
  const { t, setForceFetch } = useTranslation();

  const field = yup
    .string()
    .max(250, 'This field can not exceed 250 characters')
    .required('required');

  const schema = yup.object().shape({
    key: yup.string().min(4, 'min_4_characters').max(80, 'max_80_characters').required('required'),
    english: field,
    spanish: field,
    portuguese: field,
    french: field,
  });

  const {
    mutateAsync: updateTranslation,
    error: updateError,
    isError: updateIsError,
    isSuccess: updateIsSuccess,
    isLoading: updateIsLoading,
  } = useUpdateTranslation();
  const {
    mutateAsync: createTranslation,
    error: createError,
    isError: createIsError,
    isSuccess: createIsSuccess,
    isLoading: createIsLoading,
  } = useCreateTranslation();

  const [openToast, setOpenToast] = React.useState(false);
  const [error, setError] = React.useState('');

  // eslint-disable-next-line
  const getDefaultValues = () => {
    return translation
      ? {
          key: translation.key,
          english: translation.english,
          spanish: translation.spanish,
          portuguese: translation.portuguese,
          french: translation.french,
        }
      : {};
  };

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: getDefaultValues(),
    mode: 'all',
  });

  const {
    handleSubmit,
    formState: { errors, isValid, isDirty },
  } = methods;

  const handleCloseToast = () => {
    setOpenToast(false);
  };

  const updTranslation = (data) => {
    const translationToUpdate = {
      translation: {
        ...translation,
        english: data.english,
        spanish: data.spanish,
        french: data.french,
        portuguese: data.portuguese,
        key: data.key,
      },
      id: translation._id,
    };
    updateTranslation(translationToUpdate);
  };

  const newTranslation = (data) => {
    const translationToCreate = {
      english: data.english,
      spanish: data.spanish,
      french: data.french,
      portuguese: data.portuguese,
      key: data.key,
    };
    createTranslation(translationToCreate);
  };

  const onSubmit = handleSubmit((data) =>
    action === 'update' ? updTranslation(data) : newTranslation(data)
  );

  React.useEffect(() => {
    if (updateIsSuccess) {
      setForceFetch();
      onClose();
    }
    if (updateIsError) {
      setError(t(updateError.data.error));
      setOpenToast(true);
    }
  }, [updateIsSuccess, updateIsError, onClose, updateError, t, setForceFetch]);

  React.useEffect(() => {
    if (createIsSuccess) {
      setForceFetch();
      onClose();
    }
    if (createIsError) {
      setError(t(createError.data?.error));
      setOpenToast(true);
    }
  }, [createIsSuccess, createIsError, onClose, createError, t, setForceFetch]);

  const formTranslation = (
    <MuiContainer display='flex'>
      <Toast
        message={error}
        handleClose={handleCloseToast}
        severity='error'
        horizontal='center'
        vertical='top'
        open={openToast}
      />
      <ThemeProvider theme={themeCrowdView}>
        <MuiGridInputText item xs={12}>
          <TextField
            autoFocus
            name='key'
            margin='dense'
            type='text'
            label='Key'
            variant='outlined'
            fullWidth
            disabled={process.env.NODE_ENV !== 'development' && action === 'update'}
            error={t(errors.key?.message)}
          />
        </MuiGridInputText>
        <MuiGridInputText item xs={12}>
          <TextField
            name='english'
            margin='dense'
            type='text'
            label='English'
            variant='outlined'
            fullWidth
            error={t(errors.english?.message)}
          />
        </MuiGridInputText>
        <MuiGridInputText item xs={12}>
          <TextField
            name='spanish'
            margin='dense'
            type='text'
            label='Español'
            variant='outlined'
            fullWidth
            error={t(errors.spanish?.message)}
          />
        </MuiGridInputText>
        <MuiGridInputText item xs={12}>
          <TextField
            name='french'
            margin='dense'
            type='text'
            label='Français'
            variant='outlined'
            fullWidth
            error={t(errors.french?.message)}
          />
        </MuiGridInputText>
        <MuiGridInputText item xs={12}>
          <TextField
            name='portuguese'
            margin='dense'
            type='text'
            label='Portugais'
            variant='outlined'
            fullWidth
            error={t(errors.portuguese?.message)}
          />
        </MuiGridInputText>
      </ThemeProvider>
    </MuiContainer>
  );

  const actions = (
    <>
      <Button onClick={onClose} variant='text'>
        {t('cancel_btn')}
      </Button>
      <Button
        onClick={onSubmit}
        type='submit'
        variant='contained'
        loading={updateIsLoading || createIsLoading}
        disabled={!isValid || updateIsLoading || createIsLoading || !isDirty}
      >
        {t('save_changes')}
      </Button>
    </>
  );

  return (
    <FormProvider {...methods}>
      <form>
        <Modal
          open={open}
          onClose={onClose}
          title={
            action === 'update'
              ? `${t('update_translation')}: ${translation?.key}`
              : `${t('new_translation')}`
          }
          actions={actions}
        >
          {formTranslation}
        </Modal>
      </form>
    </FormProvider>
  );
};

export default FormModal;
