import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {PropTypes} from 'prop-types';
import WrapperWidgetUI from '../WrapperWidgetUI';
import HistogramWidgetUI from './HistogramWidgetUI'
import {setStatisticFilter, setUpdateQA} from '../../../../store/appSlice';
import {status200} from '../../../../api/status.utils';
import PopoverUI from "../Popover";
import TuneIcon from '@mui/icons-material/Tune';
import {MuiInfoOutlinedIcon} from '../../styles/categoryWidgetUI';
import {useCache} from "../../../providers/CacheContext";
import enginePaths from "../../../../api/enginePaths";
import axiosEngineInstance from "../../../../api/axios/axiosEngineInstance";

/**
 * Renders a <HistogramWidget /> component
 * @param  {object} props
 * @param  {string} props.id - ID for the widget instance.
 * @param  {string} props.title - Title to show in the widget header.
 * @param  {string} props.dataSource - ID of the data source to get the data from.
 * @param  {string} props.column - Name of the data source's column to get the data from.
 * @param  {number} props.min - Min value of the indicated column
 * @param  {number} props.max - Max value of the indicated column
 * @param  {number[]} [props.ticks] - Array of thresholds for the X axis.
 * @param  {Function} [props.formatter] - Function to format Y axis values.
 * @param  {boolean} [props.tooltip=true] - Whether to show a tooltip or not
 * @param  {Function} [props.tooltipFormatter] - Function to return the HTML of the tooltip.
 * @param  {Object} [props.wrapperProps] - Extra props to pass to [WrapperWidgetUI](https://storybook-react.carto.com/?path=/docs/widgets-wrapperwidgetui--default)
 */
function HistogramWidget({
                           id,
                           title,
                           dataSource,
                           dataRegion,
                           column,
                           answerVar,
                           ticks: _ticks = [],
                           min: _min,
                           max: _max,
                           xAxisFormatter,
                           formatter,
                           tooltip,
                           tooltipFormatter,
                           wrapperProps,
                           color,
                           weight,
                           policyId
                         }) {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const folded = useSelector(state => state.app.widgetFoldedState[id]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedBars, setSelectedBars] = useState([]);
  const activeCategoryFilters = useSelector(state => state.app.segmentationFilter);
  const activeStatisticFilters = useSelector(state => state.app.statisticFilter);
  const activeSimilarityFilters = useSelector(state => state.app.similarityFilter);
  const activeInsightsFilters = useSelector(state => state.app.insightsFilter);
  const similarityQuery = useSelector(state => state.app.similarityQuery);
  const treeMapFilter = useSelector(state => state.app.treeMapFilter);
  const [bins, setBins] = useState(9);
  const [showBinSlider, setShowBinSlider] = useState(false);
  const [binSliderlocation, setBinSliderlocation] = useState(null);
  const {cache, setCacheData} = useCache();

  const fetchData = async (data) => {
    const cache_key = JSON.stringify(data);
    if (cache[cache_key]) {
      return cache[cache_key]
    }
    const respData = await axiosEngineInstance.post(enginePaths.aggregate_statistic, data, status200).then((resp) => {
      return resp.data
    });
    setCacheData(cache_key, respData)
    return respData;
  }

  useEffect(() => {
    if(folded){
      return
    }
    computeBins().then(() => setIsLoading(false));
  }, [dataSource, column, weight, activeCategoryFilters, activeStatisticFilters, similarityQuery, activeSimilarityFilters, treeMapFilter, activeInsightsFilters,folded]);

  async function computeBins() {
    setIsLoading(true);
    let localStatisticFilter = {...activeStatisticFilters}
    delete localStatisticFilter[column];
    let localSegmentationFilter = {...activeCategoryFilters, ...(activeInsightsFilters[policyId] || {})}
    let localSimilarityQuery = similarityQuery[policyId] || {}
    let localSimilarityFilter = activeSimilarityFilters[policyId] || {}

    if (treeMapFilter) {
      Object.keys(treeMapFilter).forEach(key => {
        Object.assign(localSegmentationFilter, treeMapFilter[key])
      });
    }

    let localData = await new Promise((resolve) => {
      let response = fetchData({
        surveyId: dataSource,
        attribute: column,
        segmentation: localSegmentationFilter,
        statistics: localStatisticFilter,
        weight: weight,
        questionId: answerVar,
        similarity_query: localSimilarityQuery,
        similarity: localSimilarityFilter,
        bins: bins,
        region: dataRegion||"US"
      }).then((resp) => {
        return resp
      }).catch((error) => {
        console.log("Error fetching histogram data", error);
        setIsLoading(false);
        return {
          rows: [],
        }
      })
      resolve(response)
    });
    const rows = localData.rows;
    setData(rows);
    setIsLoading(false);
  }

  useEffect(() => {
    if(folded){
      return
    }
    computeBins().then(() => {
      setIsLoading(false);
    });
  }, [bins]);

  useEffect(() => {
    if (activeStatisticFilters && data) {
      let localSelectedBars = []
      let from = null;
      let to = null;
      let localStatisticFilter = {...activeStatisticFilters[policyId]}
      for (let prop in localStatisticFilter) {
        if (prop === column) {
          from = localStatisticFilter[prop].low;
          to = localStatisticFilter[prop].high;
          for (let i = 0; i < data.length; i++) {
            if (data[i].binStart >= from && data[i].binEnd <= to) {
              localSelectedBars.push(i);
            }
          }
          setSelectedBars(localSelectedBars);
          break;
        }

      }
    }
  }, [data, activeStatisticFilters, column]);

  const handleSelectedBarsChange = useCallback(
    (selectedBars) => {
      let localSelectedBars = selectedBars.bars;
      let statisticFilter = {...activeStatisticFilters}
      if (localSelectedBars?.length && data) {
        const thresholds = localSelectedBars.map((i) => {
          let index = Math.max(0, i);
          let left = data[index].binStart;
          let right = data[index].binEnd;

          return [left, right];
        });
        if (thresholds.length) {
          const low = thresholds[0][0];
          const high = thresholds[thresholds.length - 1][thresholds[thresholds.length - 1].length - 1];
          if (low !== undefined && low !== null && !isNaN(low) && high) {
            statisticFilter[column] = {
              low: low,
              high: high,
            }
          }
        }
        setSelectedBars(localSelectedBars);
        dispatch(
          setStatisticFilter(statisticFilter)
        );
      } else {
        delete statisticFilter[column]
        dispatch(
          setStatisticFilter(statisticFilter)
        );
        setSelectedBars([])
      }
      dispatch(setUpdateQA(true));
    },
    [column, data, id, dispatch]
  );
  wrapperProps.actions = [{
    id: 'a2',
    icon: <TuneIcon
      color={"primary"} sx={{width:'16px',height:'16px'}}/>,
    action: () => setShowBinSlider(prevState => !prevState),
    setModalLocation: setBinSliderlocation,
    disabled: false,
    show: true,
  }, {
    id: 'a3',
    icon: <MuiInfoOutlinedIcon
      color={"grey"}/>,
    action: () => alert('Info del widget'),
    disabled: false,
    show: false,
  }];
  return (
    <WrapperWidgetUI title={title} widgetId={id} {...wrapperProps}
                     isLoading={isLoading}
                     filterEnabled={selectedBars?.length > 0}>
      {(!!data.length || isLoading) && (
        <>
          <HistogramWidgetUI
            id={id}
            data={data.map((d) => d.value)}
            dataAxis={data.map((d) => d.binEnd)}
            onSelectedBarsChange={handleSelectedBarsChange}
            selectedBars={selectedBars}
            tooltip={tooltip}
            formatter={formatter}
            tooltipFormatter={tooltipFormatter}
            xAxisFormatter={xAxisFormatter}
            yAxisFormatter={formatter}
            color={color}
          />
          {(data && data.length > 0 && showBinSlider) && (
            <PopoverUI
              isSimilarityHistogram={false}
              initRangeBar={null}
              handleInitRangeCommited={null}
              showBinsSlider={showBinSlider}
              setShowBinSlider={setShowBinSlider}
              amountBins={bins + 1}
              handleBinSizeCommited={(value) => setBins(value - 1)}
              position={binSliderlocation}
            />
          )}
        </>

      )}
    </WrapperWidgetUI>
  );
}

HistogramWidget.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  dataSource: PropTypes.string.isRequired,
  column: PropTypes.string.isRequired,
  min: PropTypes.number,
  max: PropTypes.number,
  ticks: PropTypes.arrayOf(PropTypes.number),
  xAxisFormatter: PropTypes.func,
  formatter: PropTypes.func,
  tooltip: PropTypes.bool,
  tooltipFormatter: PropTypes.func,
  animation: PropTypes.bool,
  filterable: PropTypes.bool,
  global: PropTypes.bool,
  onError: PropTypes.func,
  wrapperProps: PropTypes.object,
  noDataAlertProps: PropTypes.object,
  droppingFeaturesAlertProps: PropTypes.object
};

export default HistogramWidget;
