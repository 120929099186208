/* eslint-disable no-underscore-dangle */
import React, {useEffect, useMemo, useRef, useState} from 'react';
import PropTypes from 'prop-types';

import {
  Typography,
  useTheme,
} from '@mui/material';
import HistogramChart from '../d3-charts/Histogram';
import {useTranslation} from "../../../providers/TranslationProvider";
import detectTouchScreen from '../utils/detectTouchScreen';
import { MuiGridSelectBar, MuiLink } from '../../styles/histogramWidgetUI';
import PopoverUI from '../Popover';

const IS_TOUCH_SCREEN = detectTouchScreen();

// eslint-disable-next-line no-underscore-dangle
function __generateDefaultConfig(
  {
    dataAxis,
    tooltip,
    tooltipFormatter,
    xAxisFormatter = (v) => v,
    yAxisFormatter = (v) => v
  },
  data,
  theme
) {
  return {
    grid: {
      left: theme.spacing(0),
      top: theme.spacing(2),
      right: theme.spacing(0),
      bottom: theme.spacing(3),
    },
    brush: {
      toolbox: ['rect', 'polygon', 'lineX', 'lineY', 'keep', 'clear'],
      xAxisIndex: 0,
    },
    toolbox: {
      show: true,
    },
    tooltip: {
      show: tooltip,
      trigger: 'axis',
      padding: [theme.spacing(0.5), theme.spacing(1)],
      textStyle: {
        ...theme.typography.caption,
        fontSize: 12,
        lineHeight: 16,
      },
      backgroundColor: theme.palette,
      position(point, params, dom, rect, size) {
        const position = {top: 0};

        if (size.contentSize[0] < size.viewSize[0] - point[0]) {
          // eslint-disable-next-line prefer-destructuring
          position.left = point[0];
        } else {
          position.right = size.viewSize[0] - point[0];
        }
        return position;
      },
      ...(tooltipFormatter ? {formatter: tooltipFormatter} : {}),
    },
    color: [theme.palette.secondary.main],
    xAxis: {
      type: 'category',
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
      axisLabel: {
        ...theme.typography,
        padding: [theme.spacing(0.5), 0, 0, 0],
        formatter: (v) => {
          const formatted = xAxisFormatter(v);
          return typeof formatted === 'object'
            ? `${formatted.prefix || ''}${formatted.value}${formatted.suffix || ''}`
            : formatted;
        },
      },
      data: dataAxis,
    },
    yAxis: {
      type: 'value',
      axisLabel: {
        margin: 0,
        padding: [
          0,
          0,
          theme.typography.fontSize * theme.typography.lineHeight + 4,
          0,
        ],
        show: true,
        showMaxLabel: true,
        showMinLabel: false,
        inside: true,
        color: (value) => {

          // FIXME: Workaround to show only maxlabel
          let col = 'transparent';
          const maxValue = Math.max(...data.map((d) => d || Number.MIN_SAFE_INTEGER));
          if (value > maxValue) {
            col = theme.palette;
          }

          return col;
        },
        ...theme.typography,
        formatter: (v) => {
          const formatted = yAxisFormatter(v);
          return typeof formatted === 'object'
            ? `${formatted.prefix}${formatted.value}${formatted.suffix || ''}`
            : formatted;
        },
      },
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
      splitLine: {
        show: true,
        onZero: false,
        lineStyle: {
          color: theme.palette,
        },
      },
    },
  };
}

// eslint-disable-next-line no-unused-vars
function __generateSerie(name, data, selectedBars = [], theme) {
  return [
    {
      type: 'bar',
      name,
      colorBy: 'data',
      data: data.map((value) => {
        const bar = {
          value: value,
          color: theme.palette.secondary.main,
        };
        return bar;
      }),
      barCategoryGap: 1,
      barMinWidth: '95%',
      ...(!IS_TOUCH_SCREEN && theme
        ? {
          emphasis: {
            itemStyle: {
              color: theme.palette.secondary.dark,
            },
          },
        }
        : {}),
    },
  ];
}

function HistogramWidgetUI(props) {
  const theme = useTheme();
  const {
    id,
    name,
    data = [],
    dataAxis,
    onSelectedBarsChange,
    selectedBars,
    tooltip,
    tooltipFormatter,
    xAxisFormatter,
    yAxisFormatter
  } = props;

  const chartInstance = useRef();
  const {t} = useTranslation();
  const options = useMemo(() => {
    const config = __generateDefaultConfig(
      {dataAxis, tooltip, tooltipFormatter, xAxisFormatter, yAxisFormatter},
      data,
      theme
    );
    const series = __generateSerie(name, data, selectedBars, theme);
    return {...config, series};
  }, [
    data,
    dataAxis,
    name,
    theme,
    tooltip,
    tooltipFormatter,
    xAxisFormatter,
    yAxisFormatter,
    selectedBars,
  ]);

  const clearBars = () => {
    const chart = chartInstance.current;

    onSelectedBarsChange({bars: []});
    if (chart && typeof chart.clear === 'function') {
      chart.clear();
    }
  };

  const clickEvent = (params) => {
    // eslint-disable-next-line no-empty
    if (onSelectedBarsChange) {
    }
  };

  const brushEvent = (params) => {
    if (onSelectedBarsChange) {
      onSelectedBarsChange(params);
    }
  };

  const onEvents = {
    click: clickEvent,
    brushSelected: brushEvent,
  };


  const minDecimalPlaces=(numbers) =>{
    // Step 1: Sort the list
    numbers.sort((a, b) => a - b);

    // Step 2: Initialize variables
    let minDecimalPlaces = 0;

    // Step 3: Compare adjacent numbers
    for (let i = 0; i < numbers.length - 1; i++) {
      let decimalPlaces = 0;

      // Step 4: Increase decimal places
      while (decimalPlaces<3 && parseFloat(numbers[i].toFixed(decimalPlaces)) === parseFloat(numbers[i + 1].toFixed(decimalPlaces))) {
        decimalPlaces++;
      }

      // Step 5: Update minimum decimal places
      if (decimalPlaces > minDecimalPlaces) {
        minDecimalPlaces = decimalPlaces;
      }
    }
    return minDecimalPlaces<3?minDecimalPlaces:0;
  }

  const decimalPlaces = minDecimalPlaces(dataAxis);
  return (
    <div style={{overflowX:'hidden',paddingLeft:"10px"}}>
      {onSelectedBarsChange && (
        <MuiGridSelectBar
          container
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >
          <Typography variant='caption'>
            {selectedBars && selectedBars.length>0 ? selectedBars.map((b)=>data[b]).reduce((a, b) => a + b, 0) : t('all')}
            {' '}
            {t('selected')}
          </Typography>
          {selectedBars && selectedBars.length > 0 && (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <MuiLink onClick={() => clearBars()}>
              Clear
            </MuiLink>
          )}
        </MuiGridSelectBar>
      )}
      {(data && data.length > 0) && (
        <HistogramChart
          ref={chartInstance}
          id={id}
          selectedBars={selectedBars}
          option={options}
          onEvents={onEvents}
          height={100}
          color={theme.palette.primary.main}
          filterable={true}
          xFormat={`.${decimalPlaces}f`}
        />
      )}
    </div>
  );
}

HistogramWidgetUI.propTypes = {
  data: PropTypes.arrayOf(PropTypes.number).isRequired,
  tooltip: PropTypes.bool,
  tooltipFormatter: PropTypes.func,
  xAxisFormatter: PropTypes.func,
  yAxisFormatter: PropTypes.func,
  dataAxis: PropTypes.array,
  name: PropTypes.string,
  onSelectedBarsChange: PropTypes.func,
  selectedBars: PropTypes.arrayOf(PropTypes.number),
  // eslint-disable-next-line react/require-default-props
  height: PropTypes.number,
  filterable: PropTypes.bool,

};

export default HistogramWidgetUI;
