import {Slider, styled, Typography} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

export const MuiSearchIcon = styled(SearchIcon)(({ theme }) => ({
  color: 'white',
  background:theme.palette.primary.main,
}));

export const MuiTypography= styled(Typography)(({ theme }) => ({
  color:'#212121',
  fontSize: '11px',
  fontFamily:'Montserrat',
}));

export const MuiSlider = styled(Slider)(({ theme }) => ({
  color: theme.palette.primary.main,
  marginTop: '12px',
  '& .MuiSlider-valueLabel': {
    width: 32,
    height: 23,
    borderRadius: '20% 20% 20% 20%',
    backgroundColor: '#143440',
    '&.MuiSlider-valueLabelOpen': {
      transform: 'translate(0%, -85%) scale(1)',
    },
  },
}));

