import {
  Container,
  Divider,
  Grid,
  Paper,
  styled,
  Tab,
  Tabs,
  Toolbar,
  Typography
} from '@mui/material';
import SubdirectoryArrowRightIcon
  from '@mui/icons-material/SubdirectoryArrowRight';
import {darken} from "@mui/material/styles";

export const MuiContainer = styled(Container)(() => ({
  width: '100% !important',
  height: '100% !important',
  paddingLeft: '0 !important',
  paddingRight: '0 !important',
  marginRight: '0',
  minWidth: "100vw",
  maxHeight: "100vh",
  overflow: 'hidden',
  position: 'fixed',
}));

export const MuiToolbar = styled(Toolbar)(() => ({
  backgroundColor: 'white',
  width: '100%',
  padding: '5px !important',
  marginRight: '0px',
}));

export const MuiPaper = styled(Paper)(() => ({
  borderRadius: '20px 20px 20px 20px',
  paddingLeft: '8px !important',
  width: '100%',
  zIndex: 1,
  paddingBottom: "5px",
  background: '#FAFAFA',
  boxShadow: 'none',
}));

export const MuiTabs = styled(Tabs)(({theme}) => ({
  padding: theme.spacing(0),
  marginTop: theme.spacing(1.3),
  paddingRight: "30px",
  borderBottom: '1px solid',
  borderColor: theme.palette.secondary.main,
  '& .Mui-selected': {
    fontSize: 14,
    fontFamily: 'Montserrat',
    fontWeight: 700,
    color: theme.palette.primary.main,
    transition: 'font-size 10ms, font-weight 10ms',
  },
  '& .MuiTabs-indicator': {
    height: 3,
    width: '0',
    borderRadius: '10px 10px 0 0',
    transition: 'width 0.3s',
    marginBottom: theme.spacing(0)
  },
  '& .MuiTabs-indicator[data-indicator-color="secondary"]': {
    backgroundColor: theme.palette.secondary.main,
  },
}));

export const MuiTab = styled(Tab, {shouldForwardProp: (prop) => prop !== 'selected'} )(({theme,selected}) => ({
  width: '100%',
  minWidth: '100%',
  border:'1px solid #EEEEEE',
  borderTopLeftRadius: '8px',
  borderBottomLeftRadius: '8px',
  padding:'10px 10px 12px 10px',
  marginBottom:'2px',
  textAlign: 'left',
  alignItems: 'flex-start',
  color: selected?darken(theme.palette.primary.main,0.05):'#424242',
  fontFamily:'Montserrat',
  fontWeight:600,
  fontSize:'11px',
  height:'33px',
}));

export const MuiSubdirectoryArrowRightIcon = styled(SubdirectoryArrowRightIcon)(({theme}) => ({
  width: '20px',
  height: '20px',
}));

export const MuiDivider = styled(Divider)(({theme}) => ({
  backgroundColor: '#E0E0E0',
}));

export const MuiGridItem = styled(Grid)(({theme}) => ({
  display:'flex',
  flexDirection:'column',
  marginTop:'18px'
}));

export const MuiQuestionText = styled(Typography)(({theme}) => ({
  textTransform:'uppercase',
  color:'#9E9E9E',
  fontFamily:'Montserrat',
  fontSize:'8px',
  fontWeight:600
}));

export const MuiLongName = styled(Typography)(({theme}) => ({
  textTransform:'uppercase',
  color:'#212121',
  fontFamily:'Montserrat',
  fontSize:'12px',
  fontWeight:600,
  overflow:'ellipsis',
  maxHeight:'32px',
  WebkitLineClamp:2,
  WebkitBoxOrient:'vertical',
  marginLeft:'16px'
}));

export const MuiGraphToggleContainer = styled('div')(({theme}) => ({
  height: '35px',
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  marginBottom: '-40px',
  marginTop: '18px',
  // position: 'absolute',
  padding: 0,
  // top: 0,
  // right: 15,
  zIndex: 10,
  gap: '10px'
}));

export const gridContainerStyle = {
  width: '100%',
  height: '100%',
  marginTop: '2px',
  paddingTop: 0
}

export const gridItemStyle = {
  overflowY: 'hidden',
  marginLeft: '0px !important',
  paddingRight: '0px',
  paddingTop: '0px'
}

export const treeMapGridStyle = {
  paddingLeft: 2,
  paddingTop: 0,
  marginTop: '-10px'
}

export const gridContainerTreeMapStyle = {
  background: '#FAFAFA',
  borderRadius: '20px',
  border: 'none'
}

export const paperWidgetsStyle = {
  width: '100%',
  overflowY: 'hidden'
}

export const treePageStyle = {
  width: '100%',
  height: '100%',
  maxHeight: '100%',
  overflow: 'hidden'
}

export const fabStyle = {
  position: 'fixed',
  bottom: '16px',
  right: '20px',
  backgroundColor: 'primary'
}

export const gridInsightsStyle = {
  overflow: 'hidden',
  paddingBottom: '1px',
  paddingTop: '0px',
  borderLeft: '1px solid',
  borderRadius: '20px',
  borderLeftColor: '#EEEEEE',
  width: '100%',
  top: 0,
  marginTop: 0,
  paddingLeft: '5px',
  transition: 'opacity 0.5s, right 0.5s',
};

export const divTabStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}
export const titleTabStyle = {
  fontSize: 14,
  fontFamily: 'Montserrat',
  textTransform:'none',
  fontWeight: 600
}

export const fabIconInsigthsStyle = {
  padding: 0,
  borderRadius: '30%',
  borderLeft: '1px solid #EEEEEE',
  borderTop: 'none',
  borderBottom: 'none',
  borderRight:'none',
  position: 'fixed',
  top: '50%',
  height: '50px',
  width: '16px',
  transform: `translateY(50%)`,
  transition: 'transform 0.7s ease',
  background: '#FAFAFA',
  boxShadow: 'none',
  '&:hover': {
   background: '#FAFAFA',
  },
  '&:active': {
    background: '#FAFAFA',
  },
  '&:focus': {
    background: '#FAFAFA',
  },
  ':before': {
    background: '#FAFAFA',
  }
}

export const arrowLeftStyle = {
  marginRight: '2px',
  color: '#757575'
}

export const customTabsStyle = {
  marginTop: '0px',
  paddingRight: '0px',
  borderBottom: 'none',
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
}

export const gridControlStyle = {
  minWidth:'230px',
  padding:'10px',
  gap:1,
  marginTop: '8px',
}

