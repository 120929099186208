import React, {useRef} from 'react';
import {
  TextField as MuiTextField,
  OutlinedInput,
  ThemeProvider,
  Autocomplete as MuiAutocomplete, styled, Box, InputAdornment,
} from '@mui/material';
import {useFormContext, Controller} from 'react-hook-form';


// Style and asset imports
import {rootStyle} from './styles/reactHooksForm';
import themeCrowdView from 'theme/crowdView';

const CustomTextField = React.forwardRef(({
                                            error,
                                            onChange,
                                            name,
                                            ...props
                                          }, ref) => (
  <MuiTextField
    ref={ref}
    id={name} // Makes this component testable
    name={name}
    error={Boolean(error)}
    helperText={error}
    style={{paddingTop: 5,paddingBottom: Boolean(error?.trim()||'') ? 0 : 23}}
    onChange={onChange}
    {...props}
    InputProps={{
      sx: {
        height: '55px',
        padding:'12px 0px 12px 5px',
        '& input': {
          fontSize: '16px',
          fontFamily: 'Montserrat',
        },
      },
      ...(props.InputProps||{}),
    }}
    InputLabelProps={{
      sx: {
        fontSize: '14px',
        fontFamily: 'Montserrat',
        letterSpacing: '0.5px',
      },
    }}
    FormHelperTextProps={{
      sx: {
        fontSize: '12px',
        fontFamily: 'Montserrat',
        letterSpacing: '0.4px',
      },
    }}
  />
));

const TextField = ({name, ...props}) => {
  const {control} = useFormContext();

  return (
    <ThemeProvider theme={themeCrowdView}>
      <Controller
        name={name}
        control={control}
        defaultValue=''
        fullWidth={true}
        render={({field}) => <CustomTextField margin='dense'
                                              type='text' {...field} {...props} />}
      />
    </ThemeProvider>
  );
};

const NumericSelect = ({name, ...props}) => {
  const {control} = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      defaultValue=''
      variant='outlined'
      fullWidth
      render={({field}) => (
        <CustomTextField
          sx={rootStyle}
          margin='none'
          type='number'
          variant='outlined'
          {...field}
          {...props}
        />
      )}
    />
  );
};

const ColorBox = styled(Box)(({theme, color}) => ({
  width: 24,
  height: 24,
  borderRadius: '4px',
  backgroundColor: color,
  border: `1px solid ${color}`,
}));

const ColorField = ({ name, ...props }) => {
  const { control } = useFormContext();
  const inputRef = useRef(null);

  const handleBoxClick = () => {
    inputRef.current.click();
  };

  return (
    <Controller
      name={name}
      control={control}
      defaultValue=""
      render={({ field }) => (
        <OutlinedInput
          {...field}
          {...props}
          ref={inputRef}
          type="color"
          startAdornment={
            <InputAdornment position="start">
              <ColorBox color={field.value} onClick={handleBoxClick} sx={{ cursor: 'pointer' }} />
            </InputAdornment>
          }
          sx={{
            input: {
              width: 24,
              height: 24,
              padding: 0,
              opacity: 0,
              position: 'absolute',
              cursor: 'pointer',
            },
            '& .MuiOutlinedInput-notchedOutline': {
              border: 'none',

            },
          }}
        />
      )}
    />
  );
};



const SelectField = ({name, error, ...props}) => {
  const {control} = useFormContext();
  return (
    <ThemeProvider theme={themeCrowdView}>
      <Controller
        control={control}
        name={name}
        render={({field: {value = '', onChange}}) => {
          return (
            <CustomTextField
              {...props}
              value={value}
              onChange={onChange}
              select
              id={name} // Makes this component testable
              name={name}
              error={error}
            />
          );
        }}
      />
    </ThemeProvider>
  );
};

const Autocomplete = ({
                        options = [],
                        renderInput,
                        getOptionLabel,
                        onChange: ignored,
                        defaultValue,
                        name,
                        renderOption,
                      }) => {
  const {control} = useFormContext();
  return (
    <Controller
      render={({onChange, ...props}) => (
        <MuiAutocomplete
          options={options}
          getOptionLabel={getOptionLabel}
          renderOption={renderOption}
          renderInput={renderInput}
          onChange={(e, data) => onChange(data)}
          {...props}
        />
      )}
      onChange={([, data]) => data}
      defaultValue={defaultValue}
      name={name}
      control={control}
    />
  );
};

export {TextField, NumericSelect, ColorField, SelectField, Autocomplete};
