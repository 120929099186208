import {
  Breadcrumbs,
  Grid,
  IconButton,
  styled,
  Typography,
} from '@mui/material';
import AppsIcon from '@mui/icons-material/Apps';
import HomeIcon from '@mui/icons-material/Home';
import SettingsIcon from '@mui/icons-material/Settings';
import PersonIcon from '@mui/icons-material/Person';
import SpaceDashboardIcon from '@mui/icons-material/SpaceDashboard';

export const MuiGrid = styled(Grid)(({ theme }) => ({
  width:'auto',
  minWidth: 'max-content',
  maxWidth: 'max-content'
}));

export const MuiTypography = styled(Typography)(({ theme }) => ({
  cursor: 'pointer',
  fontFamily: 'Raleway',
  textAlign: 'start',
  width: 'auto',
  marginLeft: '8px'
}));

export const MuiBreadcrumbs = styled(Breadcrumbs)(({ theme }) => ({
  '& .MuiBreadcrumbs-separator': {
    color: 'white',
    opacity: 0.7,
    margin : '2px'
  },
}));

export const MuiIconButton = styled(IconButton)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignContent: 'center',
  padding: 0,
  '&:hover': {
    backgroundColor: 'transparent'
  },
  marginRight: '2px',
  '&:active': {
    boxShadow: 'none',
    backgroundColor: 'transparent',
  },
  color: 'inherit'
}));

export const MuiAppsIcon = styled(AppsIcon)(({ theme }) => ({
  width: 18,
  height: 18,
  marginRight: 0,
  marginLeft: theme.spacing(0),
  color: 'inherit',
  padding: 0
}));

export const MuiScenarioIcon = styled(SpaceDashboardIcon)(({ theme }) => ({
  width: 18,
  height: 18,
  marginRight: 0,
  marginLeft: theme.spacing(0),
  color: 'inherit',
  padding: 0
}));

export const MuiSettingsIcon = styled(SettingsIcon)(({ theme }) => ({
  width: 18,
  height: 18,
  marginRight: 0,
  marginLeft: theme.spacing(0),
  color: 'inherit',
  padding: 0
}));

export const MuiPersonIcon = styled(PersonIcon)(({ theme }) => ({
  width: 18,
  height: 18,
  marginRight: 0,
  marginLeft: theme.spacing(0),
  color: 'inherit',
  padding: 0
}));

export const MuiHomeIcon = styled(HomeIcon)(({ theme }) => ({
  width: 18,
  height: 18,
  marginRight: 0,
  marginLeft: theme.spacing(0),
  color: 'inherit',
  padding: 0
}));

export const breadBox = {
  display: 'inline-flex',
  alignItems: 'center',
  cursor: 'pointer',
  position: 'relative',
  color: 'white',
  '&:hover': {
    color: '#B2EBF2',
    '&::after': {
      content: '""',
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      height: '2px',
      backgroundColor: '#B2EBF2'
    },
  },
}
export const breadBoxRight = {
  width: '30px',
  height: '30px',
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '&:hover': {
    backgroundColor: '#B2EBF2',
  },
  border: '2px solid white',
  color: 'white'
}
export const infoIcon ={
  height: '16px',
  width: '16px',
  marginBottom: '12px',
  color: 'white',
  '&:hover': {
    backgroundColor: '#B2EBF2',
  },
  borderRadius: '12px',
}


