import {Box, lighten, styled} from "@mui/material";

export const HintContainer=styled(Box)(({theme})=>({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  width: '100%',
  minHeight: '60px',
  padding: '12px',
  gap: '8px',
  backgroundColor: '#F5F5F5',
  borderRadius: '20px',
  border: '1px solid #EEEEEE',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: lighten(theme.palette.primary.main,0.8),
  }
}))
