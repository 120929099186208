import {
  Box,
  Grid,
  IconButton,
  Popover, Typography,
} from '@mui/material';
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { popoverStyles } from '../styles/popover';

const PopoverDescription = ({ showDescription, setShowDescription,
                              position, description }) => {

  const handleClose = () => setShowDescription(false)

  return (
    <Popover
      open={showDescription}
      anchorReference="anchorPosition"
      anchorPosition={{
        top: position.y + 30,
        left: position.x
      }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      // PaperProps={{
      //   style: popoverStyles,
      // }}
      sx={{
        borderRadius:'12px'
      }}
    >
      <Box style={{position: 'relative',display:'flex', flexDirection:'row', width: '340px'}}>

        <Grid container direction='row' alignItems={'center'} wrap={'nowrap'}
              justifyContent={'center'}>
          <Grid item xs={'true'} style={{padding: '15px 0 15px 15px'}}>
            <Typography sx={{
              fontFamily: 'Montserrat',
              fontSize: '12px',
              fontWeight: 400,
              lineHeight: '15px',
              letterSpacing: '0.004em',
              color: '#616161',
              fontStyle: 'normal',
              marginLeft: '5px'
            }} variant="caption">
              {description}
            </Typography>
          </Grid>
          <Grid container xs={"auto"} item sx={{alignItems:'flex-start',alignContent:'flex-start',height:'100%',padding:'5px 5px 0 0'}} justifyContent={'flex-end'}>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                width: '15px',
                height: '15px'
              }}
            >
              <CloseIcon sx={{width: '15px', height: '15px'}}/>
            </IconButton>
          </Grid>
        </Grid>
      </Box>
    </Popover>
  )
}

export default PopoverDescription
