import {Grid, IconButton, Popover,} from '@mui/material';
import React, {useState} from 'react';
import {useTranslation} from '../../providers/TranslationProvider';
import CloseIcon from '@mui/icons-material/Close';
import {MuiSlider, MuiTypography} from '../styles/popover';

const PopoverUI = ({
                     initRangeBar = [],
                     handleInitRangeCommited = null,
                     minBarRange = 0,
                     maxBarRange = 100,
                     showBinsSlider,
                     setShowBinSlider,
                     amountBins,
                     handleBinSizeCommited,
                     position,
                     isSimilarityHistogram
                   }) => {

  const {t} = useTranslation();
  const [localInitRange, setLocalInitRange] = useState(initRangeBar?.map(x=>Math.round(x)));
  const [localAmountBins, setLocalAmountBins] = useState(amountBins);

  const handleClose = () => setShowBinSlider(false)

  const handleBinSizeChanged = (event, value) => setLocalAmountBins(value);

  const handleInitRangeChange = (event, value) => setLocalInitRange(value);

  const onInitRangeCommited = (event, value) => handleInitRangeCommited(value)

  const onBinSizeCommited = (event, value) => handleBinSizeCommited(value);

  return (
    <Popover
      open={showBinsSlider}
      anchorReference="anchorPosition"
      anchorPosition={{
        top: position.y + 20,
        left: position.x
      }}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
     sx={{
       borderRadius: '10px',
       overflowY: 'hidden',
     }}
    >
      <Grid container direction={'column'} alignItems={'center'} sx={{width:'100%'}}>
        <Grid container item sx={{width:'100%'}} justifyContent={'flex-end'}>
          <IconButton aria-label="close" onClick={handleClose}>
            <CloseIcon sx={{width:'16px',height:'16px'}}/>
          </IconButton>
        </Grid>
        {!isSimilarityHistogram&&(
          <Grid item xs={true} sx={{padding:'0 16px 0 16px',width:'100%'}}>
            <MuiTypography id="progress-bar-selector" gutterBottom sx={{mb:'16px'}}>
              {t('amount_bins')}
            </MuiTypography>
            <MuiSlider
              valueLabelDisplay="on"
              aria-label="custom thumb label"
              value={localAmountBins}
              onChangeCommitted={onBinSizeCommited}
              onChange={handleBinSizeChanged}
              aria-labelledby="progress-bar-selector"
              min={5}
              size="small"
              max={20}
            />
          </Grid>
        )}
        {isSimilarityHistogram&&(
          <Grid item xs={true} sx={{padding:'0 16px 0 16px',width:'100%'}}>
            <MuiTypography display="block" gutterBottom sx={{mb:'16px'}}>
              Default selection range
            </MuiTypography>
            <MuiSlider
              value={localInitRange}
              size="small"
              onChange={handleInitRangeChange}
              onChangeCommitted={onInitRangeCommited}
              min={Math.round(minBarRange)}
              max={Math.round(maxBarRange)}
              valueLabelDisplay="on"
            />
          </Grid>
        )}
      </Grid>
    </Popover>
  )
}

export default PopoverUI
