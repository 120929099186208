import * as React from "react";
import { useTranslation } from "../../../../../providers/TranslationProvider";
import {
  Box,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Popover,
  Typography
} from "@mui/material";
import {
  MuiInfoIconButton,
  MuiInfoOutlinedIcon,
  MuiSegmentCaption,
  MuiSegmentTitle
} from "./styles/segmentationParameters";
import {Controller, useForm} from "react-hook-form";
import {MuiBoxPalette} from "../../../styles/formModal";
import {
  colorSchemes,
  generateScaledPalette
} from "../../../../../../utils/colorPaletteUtils";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {useState} from "react";
import CloseIcon from "@mui/icons-material/HighlightOffOutlined";


const ColorPalette = ({ scheme, currentPaletteKey, setCurrentPaletteKey }) => {
  const {t} = useTranslation();
  const { control } = useForm();

  const [anchorColorPaletteInfo, setAnchorColorPaletteInfo] = useState(null);
  const [infoText, setInfoText] = useState('');
  const [anchorEl, setAnchorEl] = React.useState(null);

  const showColorPaletteInfo = (event) => {
    let defaultText = 'info default';
    setInfoText('info sobre la paleta de colores' || defaultText);
    setAnchorColorPaletteInfo(event.currentTarget);
  }

  const handlePaletteClick = (event) => setAnchorEl(event.currentTarget);

  const closeContextualizationInfo = () => setAnchorColorPaletteInfo(null);

  const handleMenuClose = (paletteKey) => {
    setAnchorEl(null);
    if (paletteKey) setCurrentPaletteKey(paletteKey);
  };

  return (
    <Box style={{padding: "8px", width: '100%'}}>
      <Grid container item xs={6} direction='column'
            sx={{paddingRight: '16px'}}>
        <Grid item sx={{
          mb: '8px',
          display: 'flex',
          alignItems: 'center',
        }}>
          <MuiSegmentTitle variant="h6">
            {t('treemap_color_palette')}
          </MuiSegmentTitle>
          <MuiInfoIconButton onClick={showColorPaletteInfo}>
            <MuiInfoOutlinedIcon/>
          </MuiInfoIconButton>
        </Grid>
        <Grid item sx={{mb: '16px'}}>
          <MuiSegmentCaption>
            {t('information_color_palette')}
          </MuiSegmentCaption>
        </Grid>
        <Grid item>
          <Controller
            name="colorPalette"
            control={control}
            render={({ field }) => (
              <>
                <MuiBoxPalette>
                  {generateScaledPalette(field.value || scheme.colorPalette || currentPaletteKey, 20).map((color, index, array) => (
                    <Box
                      key={color}
                      sx={{
                        width: '14px',
                        height: '30px',
                        backgroundColor: color,
                        borderRadius:
                          index === 0
                            ? '4px 0 0 4px'
                            : index === array.length - 1
                            ? '0 4px 4px 0'
                            : '0',
                      }}
                    />
                  ))}
                  <IconButton onClick={handlePaletteClick} sx={{ padding: 0 }}>
                    <ArrowDropDownIcon />
                  </IconButton>
                </MuiBoxPalette>
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={() => handleMenuClose()}
                >
                  {Object.keys(colorSchemes).map((paletteKey) => (
                    <MenuItem
                      key={paletteKey}
                      onClick={() => {
                        field.onChange(paletteKey); // Update form state
                        handleMenuClose(paletteKey);
                      }}
                    >
                      {generateScaledPalette(paletteKey, 20).map((color, index, array) => (
                        <Box
                          key={color}
                          sx={{
                            width: '8px',
                            height: '30px',
                            backgroundColor: color,
                            borderRadius:
                              index === 0
                                ? '4px 0 0 4px'
                                : index === array.length - 1
                                ? '0 4px 4px 0'
                                : '0',
                          }}
                        />
                      ))}
                    </MenuItem>
                  ))}
                </Menu>
              </>
            )}
          />
        </Grid>
      </Grid>
      <Popover
        open={Boolean(anchorColorPaletteInfo)}
        anchorEl={anchorColorPaletteInfo}
        onClose={closeContextualizationInfo}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Box style={{position: 'relative', width: '420px', padding: '10px'}}>
          <Grid container direction='row'>
            <Grid item sx={{padding: '5px', maxWidth: '374px'}}>
              <Typography sx={{
                fontFamily: 'Montserrat',
                fontSize: '12px',
                fontWeight: 400,
                lineHeight: '15px',
                letterSpacing: '0.004em',
                color: '#616161',
                fontStyle: 'normal',
                marginLeft: '5px'
              }} variant="caption">
                {infoText}
              </Typography>
            </Grid>
            <Grid item>
              <IconButton
                aria-label="close"
                onClick={closeContextualizationInfo}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  width: '15px',
                  height: '15px'
                }}
              >
                <CloseIcon sx={{width: '15px', height: '15px'}}/>
              </IconButton>
            </Grid>
          </Grid>
        </Box>
      </Popover>
    </Box>
  )
}

export default ColorPalette;
