import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import axios from '../../../../api/axios/axiosInstance';
import apiPaths from '../../../../api/apiPaths';
import {status200} from '../../../../api/status.utils';
import LoadingProvider, {useLoading} from "../../../providers/LoadingProvider";
import {useTranslation} from "../../../providers/TranslationProvider";
import {Box, Divider, Grid, InputAdornment,} from "@mui/material";
import QAHistory from "./QAHistory";
import {
  dividerStyles,
  MuiLinearProgress,
  MuiQAGrid,
  MuiQueryTextField,
  QAButton
} from "./styles/QAWidget";
import enginePaths from "../../../../api/enginePaths";
import axiosEngineInstance from "../../../../api/axios/axiosEngineInstance";
import {QAHints} from "./QAHints";
import SendIcon from '@mui/icons-material/Send';
import {normalizeString} from "../../../../utils/text";
import ListItemIcon from "@mui/icons-material/ListAlt";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import SelfImprovementIcon from "@mui/icons-material/SelfImprovement";


/**
 * Renders a <HistogramWidget /> component
 * @param  {object} props
 * @param  {string} props.id - ID for the widget instance.
 * @param  {string} props.dataSource - ID of the data source to get the data from.
 * @param  {string} props.column - Name of the data source's column to get the data from.
 */
function QAWidget({
                    id,
                    dataSource,
                    dataRegion,
                    column,
                    weight,
                    policyId,
                    statVars,
                    segVars,
                    analysisObjective,
                    contextDescription,
                    audienceDescription,
                    originalQuestionText,
                    mainTopics,
                    hints
                  }) {

  const [queryText, setQueryText] = useState("");
  const [tempQueryText, setTempQueryText] = useState("");
  const activeSimilarityFilters = useSelector(state => state.app.similarityFilter);
  const activeSimilarityQuery = useSelector(state => state.app.similarityQuery);
  const activeInsightsFilters = useSelector(state => state.app.insightsFilter)
  const activeStatisticFilters = useSelector(state => state.app.statisticFilter);
  const activeSegmentationFilters = useSelector(state => state.app.segmentationFilter);
  const treeMapFilter = useSelector(state => state.app.treeMapFilter);
  const [messageHistory, setMessageHistory] = useState([]);
  const [selectedHistory, setSelectedHistory] = useState(null);
  const [userHints, setUserHints] = useState([...hints]);
  const {
    setIsSearchLoading, isSearchLoading, setProgress, setProgressMessage
  } = useLoading();
  const [isLoading, setIsLoading] = useState(false);
  const activePolicyTab = useSelector(state => state.app.selectedScenarioTab);
  const {t, lng} = useTranslation();
  const historyContainerRef = React.useRef(null);
  const [historyMaxHeight, setHistoryMaxHeight] = useState('580px'); // Default height, adjust as needed
  const hintColor = [
    {
      backgroundColor: '#FFCDD2',
      color: '#F44336'
    },
    {
      backgroundColor: '#D1C4E9',
      color: '#673AB7'
    },
    {
      backgroundColor: '#B3E5FC',
      color: '#03A9F4'
    },
    {
      backgroundColor: '#F0F4C3',
      color: '#9E9D24'
    }
  ]
  const genericHints = {
    english: [
      {
        title: 'About topic analysis',
        question: 'What are the main topics discussed in the responses?',
        icon: <Box sx={{
          display: 'flex',
          alignContent: 'center',
          alignItems: 'center',
          justifyContent: 'center',
          width: '26px',
          height: '26px',
          minHeight: '26px',
          borderRadius: '50%',
          backgroundColor: hintColor[0].backgroundColor,
        }}>
          <ListItemIcon sx={{
            color: hintColor[0].color,
            width: '16px',
            height: '16px'
          }}/>
        </Box>
      },
      {
        title: "About respondents' perceptions",
        question: "Are there predominant feelings (positive, negative, neutral) in the responses?",
        icon: <Box sx={{
          display: 'flex',
          alignContent: 'center',
          alignItems: 'center',
          justifyContent: 'center',
          width: '26px',
          height: '26px',
          minHeight: '26px',
          borderRadius: '50%',
          backgroundColor: hintColor[1].backgroundColor,
        }}>
          <QuestionAnswerIcon sx={{
            color: hintColor[1].color,
            width: '16px',
            height: '16px'
          }}/>
        </Box>
      },
      {
        title: "About improvements and actions",
        question: "What types of suggestions or recommendations do participants make most frequently?",
        icon: <Box sx={{
          display: 'flex',
          alignContent: 'center',
          alignItems: 'center',
          justifyContent: 'center',
          width: '26px',
          height: '26px',
          minHeight: '26px',
          borderRadius: '50%',
          backgroundColor: hintColor[2].backgroundColor,
        }}>
          <SelfImprovementIcon sx={{
            color: hintColor[2].color,
            width: '16px',
            height: '16px'
          }}/>
        </Box>
      }
    ],
    spanish: [
      {
        title: 'Acerca del análisis de temas',
        question: '¿Cuáles son los temas principales discutidos en las respuestas?',
        icon: <Box sx={{
          display: 'flex',
          alignContent: 'center',
          alignItems: 'center',
          justifyContent: 'center',
          width: '26px',
          height: '26px',
          minHeight: '26px',
          borderRadius: '50%',
          backgroundColor: hintColor[0].backgroundColor,
        }}>
          <ListItemIcon sx={{
            color: hintColor[0].color,
            width: '16px',
            height: '16px'
          }}/>
        </Box>
      },
      {
        title: 'Acerca de las percepciones de los encuestados',
        question: '¿Existen sentimientos predominantes (positivos, negativos, neutrales) en las respuestas?',
        icon: <Box sx={{
          display: 'flex',
          alignContent: 'center',
          alignItems: 'center',
          justifyContent: 'center',
          width: '26px',
          height: '26px',
          minHeight: '26px',
          borderRadius: '50%',
          backgroundColor: hintColor[1].backgroundColor,
        }}>
          <QuestionAnswerIcon sx={{
            color: hintColor[1].color,
            width: '16px',
            height: '16px'
          }}/>
        </Box>
      },
      {
        title: 'Acerca de mejoras y acciones',
        question: '¿Qué tipos de sugerencias o recomendaciones hacen los participantes con mayor frecuencia?',
        icon: <Box sx={{
          display: 'flex',
          alignContent: 'center',
          alignItems: 'center',
          justifyContent: 'center',
          width: '26px',
          height: '26px',
          minHeight: '26px',
          borderRadius: '50%',
          backgroundColor: hintColor[2].backgroundColor,
        }}>
          <SelfImprovementIcon sx={{
            color: hintColor[2].color,
            width: '16px',
            height: '16px'
          }}/>
        </Box>
      }
    ],
    french: [
      {
        title: "À propos de l'analyse des sujets",
        question: 'Quels sont les principaux sujets discutés dans les réponses ?',
        icon: <Box sx={{
          display: 'flex',
          alignContent: 'center',
          alignItems: 'center',
          justifyContent: 'center',
          width: '26px',
          height: '26px',
          minHeight: '26px',
          borderRadius: '50%',
          backgroundColor: hintColor[0].backgroundColor,
        }}>
          <ListItemIcon sx={{
            color: hintColor[0].color,
            width: '16px',
            height: '16px'
          }}/>
        </Box>
      },
      {
        title: 'À propos des perceptions des répondants',
        question: 'Y a-t-il des sentiments prédominants (positifs, négatifs, neutres) dans les réponses ?',
        icon: <Box sx={{
          display: 'flex',
          alignContent: 'center',
          alignItems: 'center',
          justifyContent: 'center',
          width: '26px',
          height: '26px',
          minHeight: '26px',
          borderRadius: '50%',
          backgroundColor: hintColor[1].backgroundColor,
        }}>
          <QuestionAnswerIcon sx={{
            color: hintColor[1].color,
            width: '16px',
            height: '16px'
          }}/>
        </Box>
      },
      {
        title: 'À propos des améliorations et actions',
        question: 'Quels types de suggestions ou recommandations les participants font-ils le plus souvent ?',
        icon: <Box sx={{
          display: 'flex',
          alignContent: 'center',
          alignItems: 'center',
          justifyContent: 'center',
          width: '26px',
          height: '26px',
          minHeight: '26px',
          borderRadius: '50%',
          backgroundColor: hintColor[2].backgroundColor,
        }}>
          <SelfImprovementIcon sx={{
            color: hintColor[2].color,
            width: '16px',
            height: '16px'
          }}/>
        </Box>
      }
    ],
    portuguese: [
      {
        title: 'Sobre a análise de tópicos',
        question: 'Quais são os principais tópicos discutidos nas respostas?',
        icon: <Box sx={{
          display: 'flex',
          alignContent: 'center',
          alignItems: 'center',
          justifyContent: 'center',
          width: '26px',
          height: '26px',
          minHeight: '26px',
          borderRadius: '50%',
          backgroundColor: hintColor[0].backgroundColor,
        }}>
          <ListItemIcon sx={{
            color: hintColor[0].color,
            width: '16px',
            height: '16px'
          }}/>
        </Box>
      },
      {
        title: 'Sobre as percepções dos respondentes',
        question: 'Existem sentimentos predominantes (positivos, negativos, neutros) nas respostas?',
        icon: <Box sx={{
          display: 'flex',
          alignContent: 'center',
          alignItems: 'center',
          justifyContent: 'center',
          width: '26px',
          height: '26px',
          minHeight: '26px',
          borderRadius: '50%',
          backgroundColor: hintColor[1].backgroundColor,
        }}>
          <QuestionAnswerIcon sx={{
            color: hintColor[1].color,
            width: '16px',
            height: '16px'
          }}/>
        </Box>
      },
      {
        title: 'Sobre melhorias e ações',
        question: 'Quais tipos de sugestões ou recomendações os participantes fazem com mais frequência?',
        icon: <Box sx={{
          display: 'flex',
          alignContent: 'center',
          alignItems: 'center',
          justifyContent: 'center',
          width: '26px',
          height: '26px',
          minHeight: '26px',
          borderRadius: '50%',
          backgroundColor: hintColor[2].backgroundColor,
        }}>
          <SelfImprovementIcon sx={{
            color: hintColor[2].color,
            width: '16px',
            height: '16px'
          }}/>
        </Box>
      }
    ],
    arabic: [
      {
        title: 'About topic analysis',
        question: 'What are the main topics discussed in the responses?',
        icon: <Box sx={{
          display: 'flex',
          alignContent: 'center',
          alignItems: 'center',
          justifyContent: 'center',
          width: '26px',
          height: '26px',
          minHeight: '26px',
          borderRadius: '50%',
          backgroundColor: hintColor[0].backgroundColor,
        }}>
          <ListItemIcon sx={{
            color: hintColor[0].color,
            width: '16px',
            height: '16px'
          }}/>
        </Box>
      },
      {
        title: "About respondents' perceptions",
        question: "Are there predominant feelings (positive, negative, neutral) in the responses?",
        icon: <Box sx={{
          display: 'flex',
          alignContent: 'center',
          justifyContent: 'center',
          width: '26px',
          height: '26px',
          borderRadius: '50%',
          backgroundColor: hintColor[1].backgroundColor,
        }}>
          <QuestionAnswerIcon sx={{
            color: hintColor[1].color,
            width: '16px',
            height: '16px'
          }}/>
        </Box>
      },
      {
        title: "About improvements and actions",
        question: "What types of suggestions or recommendations do participants make most frequently?",
        icon: <Box sx={{
          display: 'flex',
          alignContent: 'center',
          alignItems: 'center',
          justifyContent: 'center',
          width: '26px',
          height: '26px',
          minHeight: '26px',
          borderRadius: '50%',
          backgroundColor: hintColor[2].backgroundColor,
        }}>
          <SelfImprovementIcon sx={{
            color: hintColor[2].color,
            width: '16px',
            height: '16px'
          }}/>
        </Box>
      }
    ],
  };
  useEffect(() => {
    function updateSize() {

      // Capture the window height
      const windowHeight = window.innerHeight;
      // Adjust these values based on your layout specifics
      const knownHeights = 240; // Sum of heights of other components (loading bar, pagination, etc.)
      const calculatedHeight = windowHeight - knownHeights;
      if (calculatedHeight > 0) {
        setHistoryMaxHeight(`${calculatedHeight}px`);
      }
    }

    updateSize();
    window.addEventListener('resize', updateSize);

    // Cleanup the event listener on component unmount
    return () => window.removeEventListener('resize', updateSize);
  }, [queryText, selectedHistory]); // Empty dependency array means this effect runs once on mount

  useEffect(() => {
    let localHints = hints?.length > 0 ? [...hints] : (genericHints[lng] || genericHints.english)
    const filteredHints = localHints.filter(hint => !messageHistory.some(mh => mh.userQuery === hint.question));
    console.log(filteredHints)
    setUserHints(filteredHints)
  }, [messageHistory])

  useEffect(() => {
    if (selectedHistory && messageHistory.findIndex(mh=> mh.userQuery === selectedHistory.userQuery) === 0 && historyContainerRef?.current) {
      historyContainerRef.current.scrollTo({top: 0, behavior: 'smooth'});
    }
  }, [selectedHistory,messageHistory]);

  const fetchData = async (data) => {
    return await axiosEngineInstance.post(enginePaths.qa, data, status200).then((resp) => {
      return resp.data
    });
  }

  const fetchMessageHistory = async () => {
    return await axios.get(`${apiPaths.qa_history}?widget_id=${id}`, status200).then((resp) => {
      return resp.data
    })
  }

  const deleteMessageHistory = async (userQuery) => {
    return await axios.delete(`${apiPaths.delete_qa_history}?user_query=${userQuery}&widget_id=${id}`, status200).then((resp) => resp.data)
  }
  const updateMessageHistory = async (data) => {
    return await axios.post(apiPaths.update_qa_history, data, status200).then((resp) => {
      return resp.data
    })
  }

  function currentHistoryFilters(similarity = (activeSimilarityFilters[policyId] || {}), statistics = activeStatisticFilters, segmentation = activeSegmentationFilters, treeMap = treeMapFilter) {
    const findMinDecimalPlaces = (low, high) => {
      let decimalPlaces = 0;
      while (parseFloat(low.toFixed(decimalPlaces)) === parseFloat(high.toFixed(decimalPlaces))) {
        decimalPlaces++;
      }
      return decimalPlaces;
    };

    const createFilterObject = (type, prettyText, value, key) => ({
      type, prettyText, value: {[key]: value}, policyId
    });

    const processRangeFilter = (filter, type, varList) => {
      return Object.keys(filter).reduce((acc, key) => {
        if (filter[key]) {
          const {low, high} = filter[key];
          let minDecimals = findMinDecimalPlaces(low, high);
          let varLabel = type === 'statistic' ? varList.find(varItem => varItem.name === key)?.label : "Similarity";
          let prettyText = `${low.toFixed(minDecimals)} < ${varLabel} < ${high.toFixed(minDecimals)}`;
          const filterObj = createFilterObject(type, prettyText, filter[key], key);
          if (type === 'similarity') {
            filterObj.similarityQuery = activeSimilarityQuery[policyId] || ''
          }
          acc.push(filterObj);
        }
        return acc;
      }, []);
    };

    const processSegmentationFilters = (filter, type, segmentationVariables) => {
      return Object.keys(filter).reduce((acc, key) => {
        if (filter[key]?.length > 0) {
          let values = filter[key].map(val => String(val)).join(" | ");
          let varLabel = segmentationVariables.find(varItem => varItem.name === key)?.label;
          let prettyText = type === 'treemap' ? `Topic: ${values}` : `${varLabel}: ${values}`;
          acc.push(createFilterObject(type, prettyText, filter[key], key));
        }
        return acc;
      }, []);
    };
    let localTreeMapFilter = {};
    if (treeMap) {
      Object.keys(treeMap).forEach(key => {
        Object.assign(localTreeMapFilter, treeMap[key])
      });
    }
    let filters = [];
    filters.push(...processRangeFilter(similarity, "similarity", statVars));
    filters.push(...processRangeFilter({...(statistics)}, "statistic", statVars));
    filters.push(...processSegmentationFilters({...(segmentation)}, "segmentation", segVars));
    filters.push(...processSegmentationFilters(localTreeMapFilter, "treemap", segVars));//treeMapFilter[policyId] || {}
    filters.push(...processSegmentationFilters((activeInsightsFilters[policyId] || {}), "insights", segVars));

    return filters;
  }


  const generate_query_response = async () => {
    if (!queryText || queryText === "") {
      setSelectedHistory(null);
      return;
    }
    let localSegmentationFilters = {...activeSegmentationFilters, ...(activeInsightsFilters[policyId] || {})};
    Object.keys(treeMapFilter).forEach(key => {
      Object.assign(localSegmentationFilters, treeMapFilter[key]);
    });
    let localFilters = {
      statistic: {...activeStatisticFilters},
      segmentation: localSegmentationFilters,
      similarity: activeSimilarityFilters[policyId] || {}
    };
    const variables = segVars.map(sv => {
      return {
        propName: sv.name,
        label: sv.label,
        description: sv.description,
        category: "categorical"
      }
    });
    variables.push({
      propName: `${column}_label`,
      label: "Main topics",
      description: "Topics referenced in the answer to the question",
      category: "categorical"
    })
    try {
      let localData = await fetchData({
        surveyId: dataSource,
        questionId: column,
        weight: weight,
        query: queryText || " ",
        statistics: localFilters.statistic,
        segmentation: localFilters.segmentation,
        similarity: localFilters.similarity,
        similarityQuery: activeSimilarityQuery[policyId] || '',
        region: dataRegion || "US",
        analysisObjective: analysisObjective || '',
        contextDescription: contextDescription || '',
        stakeholdersDescription: audienceDescription || '',
        originalQuestionText: originalQuestionText || '',
        variablesDefinition: variables
      });

      let {query_response} = localData;
      if (queryText && query_response) {
        query_response = query_response.replace("``` html", "").replace("```", "")
        let filters = currentHistoryFilters();
        const historyItem = createHistoryItem(query_response, filters);
        setSelectedHistory(historyItem);
        const updatedHistory = await updateMessageHistory(historyItem);
        if (updatedHistory && updatedHistory.history) {
          setMessageHistory(updatedHistory.history);
          setSelectedHistory(historyItem);
        }
      }

    } catch (err) {
      console.error("Error fetching similarity data", err);
    }
  };


  const getCurrentDateFormatted = () => {
    const now = new Date();

    const day = String(now.getDate()).padStart(2, '0');
    const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = now.getFullYear();

    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');

    return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
  };

  const createHistoryItem = (localQueryResponse, filters) => {
    return {
      widgetId: id,
      userQuery: queryText,
      aiAnswer: localQueryResponse,
      filters: filters,
      policyId: policyId,
      createdAt: getCurrentDateFormatted()
    };
  };

  const fetchDataActivePolicyTab = async () => {
    if (activePolicyTab === policyId) {
      if (!selectedHistory && queryText) {
        setIsLoading(true);
        setIsSearchLoading(true);
        setProgress(0);
        setProgressMessage('');
        await generate_query_response();
        setIsLoading(false);
        setIsSearchLoading(false);
      }
    }
  };

  const fetchDataHistory = async () => {
    if (!messageHistory || messageHistory.length === 0) {
      setIsLoading(true);
      const localHistory = await fetchMessageHistory();
      if (localHistory && localHistory.history) {
        setMessageHistory(localHistory.history);
        setIsLoading(false);
      }
    }
  };

  React.useEffect(() => {
    fetchDataActivePolicyTab();
  }, [queryText]);

  React.useEffect(() => {
    fetchDataHistory();
  }, [id]);

  const handleDeleteHistory = async (queryText) => {
    setIsLoading(true);
    if (selectedHistory?.userQuery === queryText) {
      await handleSelectFromHistory(null);
    }
    const updatedHistory = await deleteMessageHistory(queryText)
    if (updatedHistory && updatedHistory.history) {
      setMessageHistory(updatedHistory.history);
      setIsLoading(false);
    }
  }
  const handleSelectFromHistory = (historyItem) => {
    if (historyItem) {
      setQueryText(historyItem.userQuery);
    } else {
      setQueryText('');
    }
    setSelectedHistory(historyItem);
  }


  const handleSearchChange = (event) => {
    const inputText = event.target.value;
    setTempQueryText(inputText);
    if (inputText === '' && inputText !== queryText) {
      setQueryText(inputText);
    }
  };

  const submitQuery = async () => {
    if (queryText !== tempQueryText) {
      setSelectedHistory(null);
      setQueryText(tempQueryText);
      setTempQueryText('');
    } else if (queryText && selectedHistory) {
      setIsLoading(true);
      setIsSearchLoading(true);
      setSelectedHistory(null);
      await generate_query_response();
      setIsLoading(false);
      setIsSearchLoading(false);
    }
  }

  const refreshQuery = async () => {
    setIsLoading(true);
    await generate_query_response();
    setIsLoading(false);
  }


  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        padding: '0px 1px 0px 1px',
        backgroundColor: '#FAFAFA'
      }}>
      <LoadingProvider>
        {(isLoading) ? <MuiLinearProgress/> : null}
        <Grid container item direction='row' xs={12}
              alignItems='center' alignContent='center'
              justifyContent={'flex-start'}
              sx={{width: '100%', marginTop: '18px'}} columnGap={'8px'}>
          <Grid container item xs={true} alignContent='center'>
            <MuiQueryTextField
              value={tempQueryText}
              onChange={(!isLoading && !isSearchLoading) ? handleSearchChange : () => {
              }}
              placeholder={t('qa_placeholder')}
              onKeyPress={(e) => (e.key === 'Enter') ? submitQuery() : null}
              disabled={isLoading || isSearchLoading}
              InputProps={{
                endAdornment: (<InputAdornment
                  position="end"
                >
                  <QAButton
                    onClick={() => submitQuery()}
                    disabled={isLoading || isSearchLoading}>
                    <SendIcon
                      sx={{width: '20px', height: '20px', fill: 'white'}}/>
                  </QAButton>
                </InputAdornment>),
                sx: {alignItems: 'center'},
                disableUnderline: true,
              }}
            />
          </Grid>
        </Grid>
        <Divider
          style={dividerStyles}/>
        <MuiQAGrid item xs={12} aria-disabled={isLoading}
                   maxHeight={historyMaxHeight}
                   sx={{maxHeight: historyMaxHeight, overflowY: 'auto'}}
                   ref={historyContainerRef}>
          {(messageHistory && messageHistory.length > 0) && (
            <QAHistory
              history={messageHistory}
              widgetId={id}
              selectedHistory={selectedHistory}
              onSelectHistory={handleSelectFromHistory}
              onDeleteHistory={handleDeleteHistory}
              updateHistoryItem={refreshQuery}
            />
          )}
          <QAHints
            setQuestion={setQueryText}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            policyId={policyId}
            hints={userHints}
          />
        </MuiQAGrid>
      </LoadingProvider>
    </Box>);
}


export default QAWidget;
