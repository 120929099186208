import {
  Box,
  FormControl,
  Grid,
  IconButton,
  Popover,
  Radio,
  RadioGroup,
  Typography
} from "@mui/material";
import {
  useTranslation
} from "../../../../../../../providers/TranslationProvider";
import * as React from "react";
import {useEffect, useState} from "react";
import {
  MuiInfoIconButton,
  MuiInfoOutlinedIcon,
  MuiOutlinedButton,
  MuiRadioButton,
  MuiSelectedToggleButton,
  MuiToggleButton,
  MUIToggleGroup
} from "./styles/verticalClassificationControl";
import {generateID} from "../../../../../../../../utils/utils";
import axios from "../../../../../../../../api/axios/axiosInstance";
import axiosEngineInstance
  from "../../../../../../../../api/axios/axiosEngineInstance";
import apiPaths from "../../../../../../../../api/apiPaths";
import enginePaths from "../../../../../../../../api/enginePaths";
import {status200} from "../../../../../../../../api/status.utils";
import {useSnackbar} from "notistack";
import {useLoading} from "../../../../../../../providers/LoadingProvider";
import CloseIcon from "@mui/icons-material/HighlightOffOutlined";

export const VerticalClassificationControls = ({
                                                 question,
                                                 collection,
                                                 dataRegion,
                                                 handleQuestionChange,
                                                 analysisObjective,
                                                 contextDescription,
                                                 audienceDescription
                                               }) => {
  const [maxClassificationTopics, setMaxClassificationTopics] = useState(1);
  const {enqueueSnackbar} = useSnackbar();
  const {t, lng} = useTranslation();
  const [classificationInfoAnchor, setClassificationInfoAnchor] = useState(null);
  const [sampleSize, setSampleSize] = useState(1);
  const {
    setIsClassificationLoading,
    setTitle,
    setShowProgressBar,
    setProgressMessage,
    setProgress
  } = useLoading();

  const initialize = () => {
    setMaxClassificationTopics(question?.maxClassificationTopics || 1);
  }

  useEffect(() => {
    initialize();
  }, [question]);

  const handleClassifyWithTopics = async () => {
    setShowProgressBar(true);
    setProgress(0);
    setIsClassificationLoading(true);
    setTitle("Categorizing your data with topics...");
    let requestID = await submitCustomClassification(sampleSize);
    if (requestID) {
      const representativityData = await pollTaskProgress(requestID)
      const updatedTopics = representativityData?.updatedTopics;
      if (updatedTopics) {
        handleQuestionChange({
          ...question,
          topics: updatedTopics,
          classified: sampleSize === 1,
          maxClassificationTopics: maxClassificationTopics
        });
        successToast("Question successfully classified");
      } else {
        errorToast(`Failed to classify '${question.name}'`)
      }
    }
    setShowProgressBar(false);
    setProgressMessage('');
    setIsClassificationLoading(false);
    setProgress(0);
  }

  const successToast = (msg) => {
    enqueueSnackbar(msg, {variant: 'success'})
  }

  const errorToast = (msg) => {
    enqueueSnackbar(msg, {variant: 'error'})
  }


  const submitCustomClassification = async (sampleSize = 1) => {
    const requestId = generateID();
    let sourceClassificationVar = !!question.parentPolicy ? (question.parentPolicy.answerVar + "_label") : null;
    if (question.enabledSegmentClassification) {
      sourceClassificationVar = question.selectedConditionalSegment?.propName || null;
    }
    const payload = {
      surveyId: collection,
      questionId: question.answerVar,
      sourceClassificationVariable: sourceClassificationVar,
      parentTopics: !!question.parentPolicy ? question.parentPolicy.topics : null,
      topics: question.topics,
      model: "llm",
      lang: lng,
      requestId: requestId,
      saveClassification: true,
      classification_type: "topics",
      max_topics: maxClassificationTopics,
      region: dataRegion,
      sampleSize: sampleSize,
      updateIcons: true,
      analysisObjective: analysisObjective||'',
      contextDescription: contextDescription||'',
      stakeholderDescription: audienceDescription||'',
      originalQuestionText: question.longName||''
    };

    try {
      axiosEngineInstance.post(!!sourceClassificationVar ? enginePaths.partial_classification : enginePaths.classification, payload, status200).catch((error)=>{
        console.error("Classification failed",error);
        errorToast(`Error computing classification for Question: ${question.name}`);
      });
    } catch (error) {
      console.error(error);
      errorToast(`Error computing classification for Question: ${question.name}`);
    }
    return requestId
  };


  const pollTaskProgress = async (requestId) => {
    let countFails = 0
    while (countFails < 10) {
      try {
        const progress_response = await axios.get(`${apiPaths.progress_status}?id=${requestId}`, status200);
        const response_body = progress_response.data;

        if (!response_body || !response_body.data) {
          await new Promise((resolve) => setTimeout(resolve, 1200));
          continue;
        }

        const {message, status, progress, additionalData} = response_body.data;

        if (status === 'failed' || status === 'error') {
          return null;

        } else if (status === 'success' && additionalData) {
          return additionalData;
        }

        setProgressMessage(`${message}`);
        let norm_progress = Math.min(progress, 100)
        setProgress(norm_progress);

      } catch (e) {
        console.error(e);
        countFails++;
      }
      await new Promise((resolve) => setTimeout(resolve, 1200));
    }
    return null;
  };

  const disabled = !question.topics
    || question.topics.length === 0
    || question.topics.reduce((sum, current) => sum + Math.max(1, current.subtopics?.length || 0), 0) < 3

  return (
    <Grid
      container
      direction='column'
      sx={{
        width: '100%',
        height: '510px',
        padding: '4px 0px 4px 0px',
      }}
      rowGap={'24px'}
      alignItems='center'
      alignContent='center'
      id="classification-settings-container"
    >
      <Grid container item alignContent='center' sx={{pl: '16px'}}
            wrap={'nowrap'}>
        <Typography sx={{
          fontFamily: 'Raleway',
          fontSize: '16px',
          fontWeight: 400,
          lineHeight: '19px',
          letterSpacing: '0.015em',
          color: '#616161'
        }}>
          {t('classification_settings') || 'Classification settings'}
        </Typography>
        <MuiInfoIconButton
          onClick={(evt) => setClassificationInfoAnchor(evt.currentTarget)}>
          <MuiInfoOutlinedIcon/>
        </MuiInfoIconButton>
      </Grid>
      <Grid container item direction={'column'} justifyContent='flex-start'
            rowGap={'8px'} sx={{pl: '16px'}}>
        <Grid item>
          <Typography
            sx={{
              fontFamily: 'Montserrat',
              fontSize: '10px',
              fontWeight: 400,
              fontStyle: 'normal',
              letterSpacing: '0.015em',
              textTransform: 'uppercase',
              color: '#424242',
              lineHeight: '12px',
            }}
          >
            {t('max_topics_per_quote')}:
          </Typography>
        </Grid>
        <Grid container direction={'row'} item justifyContent='flex-start'>
          <MUIToggleGroup
            exclusive
            value={maxClassificationTopics}
            onChange={(event, value) => setMaxClassificationTopics(value)}
            disabled={disabled}
          >
            {(maxClassificationTopics === 1) ? (<MuiSelectedToggleButton
              value={1}>1</MuiSelectedToggleButton>) : (
              <MuiToggleButton value={1}>1</MuiToggleButton>)}
            {(maxClassificationTopics === 2) ? (
              <MuiSelectedToggleButton
                value={2}>2</MuiSelectedToggleButton>) : (
              <MuiToggleButton value={2}>2</MuiToggleButton>)}
            {(maxClassificationTopics === 3) ? (
              <MuiSelectedToggleButton
                value={3}>3</MuiSelectedToggleButton>) : (
              <MuiToggleButton value={3}>3</MuiToggleButton>)}
          </MUIToggleGroup>
        </Grid>
      </Grid>
      <Grid container item direction='column' justifyContent='flex-start'
            sx={{pl: '16px'}}>
        <Grid item>
          <Typography
            sx={{
              fontFamily: 'Montserrat',
              fontSize: '10px',
              fontWeight: 400,
              fontStyle: 'normal',
              letterSpacing: '0.015em',
              textTransform: 'uppercase',
              color: '#424242',
              lineHeight: '12px',
            }}
          >
            {t('classification_type')}:
          </Typography>
        </Grid>
        <Grid item>
          <FormControl>
            <RadioGroup value={sampleSize}
                        onChange={(evt, value) => setSampleSize(parseFloat(value))}>
              <MuiRadioButton
                value={1}
                control={<Radio/>}
                label={t('full_classification')}
                sx={{}}
              />
              <MuiRadioButton
                value={0.1}
                control={<Radio/>}
                label={t('partial_classification_ctrl')}
              />
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>
      <Grid item
            sx={{width: '100%', paddingLeft: '16px', paddingRight: '16px'}}>
        <MuiOutlinedButton
          variant={'outlined'}
          disabled={disabled}
          onClick={() => handleClassifyWithTopics()}
        >
          {t('classify')}
        </MuiOutlinedButton>
      </Grid>
      <Popover
        open={Boolean(classificationInfoAnchor)}
        anchorEl={classificationInfoAnchor}
        onClose={() => setClassificationInfoAnchor(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Box style={{position: 'relative', width: '420px', padding: '10px'}}>
          <Grid container direction='row'>
            <Grid item sx={{padding: '5px', maxWidth: '374px'}}>
              <Typography sx={{
                fontFamily: 'Montserrat',
                fontSize: '12px',
                fontWeight: 400,
                lineHeight: '15px',
                letterSpacing: '0.004em',
                color: '#616161',
                fontStyle: 'normal',
                marginLeft: '5px'
              }} variant="caption">
                {t('classification_info_text')}
              </Typography>
            </Grid>
            <Grid item>
              <IconButton
                aria-label="close"
                onClick={() => setClassificationInfoAnchor(null)}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  width: '15px',
                  height: '15px'
                }}
              >
                <CloseIcon sx={{width: '15px', height: '15px'}}/>
              </IconButton>
            </Grid>
          </Grid>
        </Box>
      </Popover>
    </Grid>
  )
}
