import {useTranslation} from "../../../../providers/TranslationProvider";
import {Box, Typography} from "@mui/material";
import {divTitleStyles} from "../../../../common/styles/segments";
import {
  divTabStyle,
  MuiSubdirectoryArrowRightIcon,
  MuiTab,
  MuiTabs,
  titleTabStyle
} from "./styles/treeView";
import FilterIcon from "@mui/icons-material/FilterList";
import * as React from "react";

export const Questions = ({
                            questions,
                            activeTab,
                            handleTabsChange,
                            localTreeMapFilter,
                          }) => {
  const {t} = useTranslation();

  return (
    <Box sx={{flexDirection: 'column',borderRight:'none',mt:'16px'}}>
      <div style={divTitleStyles}>
        <Typography sx={{
          fontSize: 14,
          textTransform: 'uppercase',
          fontWeight:700,
          lineHeight:'17px',
          fontStyle:'normal',
          fontFamily:'Montserrat',
          color:'#212121'
        }}>
          {t('questions')}
        </Typography>
      </div>
      <MuiTabs
        sx={{
          marginTop: '0px',
          paddingRight: '0px',
          paddingLeft:'16px',
          borderBottom: 'none',
          borderRight:'none',
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
        }}
        variant="scrollable"
        scrollButtons="auto"
        value={activeTab}
        onChange={($event, value) => handleTabsChange($event, value)}
        orientation="vertical"
        indicatorColor="secondary"
      >
        {questions.map((item, index) => {
          let treeFilterApplied = false;
          if (
            localTreeMapFilter[item.policy_id] &&
            localTreeMapFilter[item.policy_id][`${item.config.policy.answerVar}_label`] &&
            localTreeMapFilter[item.policy_id][`${item.config.policy.answerVar}_label`].length > 0
          ) {
            treeFilterApplied = true;
          }
          return (
            <MuiTab
              sx={{
                background: index === activeTab ? '#FAFAFA' : '#FFFFFF',
                borderRight: 'none',
                minWidth:'100%',
              }}
              selected={index===activeTab}
              label={
                <div style={divTabStyle}>
                  {!!item.config.policy.parentPolicy && (
                    <MuiSubdirectoryArrowRightIcon
                      sx={{color: activeTab === index ? 'primary' : '#424242'}}
                    />
                  )}
                  <span style={titleTabStyle}>{item.name}</span>
                  {treeFilterApplied && (
                    <FilterIcon
                      style={{marginLeft: '5px'}}
                      color={activeTab === index ? 'primary' : '#424242'}
                    />
                  )}
                </div>
              }
              value={index}
              key={item.policy_id + '_' + item.name}
              data-cy={item.name}
            />
          );
        })}
      </MuiTabs>
    </Box>
  );
};
