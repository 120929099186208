import {Chip, lighten, MenuItem, styled} from "@mui/material";
import {darken} from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Cancel";
export const MuiChip = styled(Chip)(({theme}) => ({
  maxWidth: '100%',
  overflow: 'visible',
  fontFamily: 'Montserrat',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '12px',
  letterSpacing: '0.015em',
  textTransform: 'uppercase',
  fontSize: '10px',
  color: darken(theme.palette.primary.main, 0.3),
  borderColor: 'transparent',
  opacity: 1.0,
  height: '29px',
  backgroundColor:lighten(theme.palette.primary.main, 0.8),
}));

export const MuiCloseIcon=styled(CloseIcon)(({theme}) => ({
  color: `${darken(theme.palette.primary.main, 0.3)} !important`,
  opacity: 1.0,
  width:'16px',
  height:'16px'
}))

export const MuiMenuItem=styled(MenuItem)(({theme}) => ({
  '&:hover': {
    backgroundColor: "#FFFFFF",
  },
  alignItems:'flex-start',
  padding:'2px 12px'
}));
