import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import CategoryWidgetUI from './CategoryWidgetUI';
import WrapperWidgetUI from '../WrapperWidgetUI';
import {setInsightsFilter, setUpdateQA} from '../../../../store/appSlice';
import {status200} from '../../../../api/status.utils';
import {useTheme} from "@mui/material";
import {MuiInfoOutlinedIcon} from '../../styles/categoryWidgetUI';
import {useCache} from "../../../providers/CacheContext";
import enginePaths from "../../../../api/enginePaths";
import axiosEngineInstance from "../../../../api/axios/axiosEngineInstance";

/**
 * Renders a <CategoryWidget /> component
 * @param  {object} props
 * @param  {string} props.id - ID for the widget instance.
 * @param  {string} props.title - Title to show in the widget header.
 * @param  {string} props.dataSource - ID of the data source to get the data from.
 * @param  {string} props.column - Name of the data source's column to get the data from.
 */
function SegmentsWidget({
                          id,
                          title,
                          column,
                          predefinedCategories,
                          answerVar,
                          dataSource,
                          dataRegion,
                          wrapperProps,
                          weight,
                          policyId
                        }) {

  const dispatch = useDispatch();
  const [selectedCategories, setSelectedCategories] = useState([]);
  const activeCategoryFilters = useSelector(state => state.app.segmentationFilter);
  const activeStatisticFilters = useSelector(state => state.app.statisticFilter);
  const activeSimilarityFilters = useSelector(state => state.app.similarityFilter);
  const activeInsightFilters = useSelector(state => state.app.insightsFilter);
  const treeMapFilter = useSelector(state => state.app.treeMapFilter);
  const similarityQuery = useSelector(state => state.app.similarityQuery);
  const [isLoading, setIsLoading] = useState(true)
  const [data, setData] = useState([])
  const theme = useTheme();
  const {cache, setCacheData} = useCache();

  const fetchData = async (data) => {
    const cache_key = JSON.stringify(data);
    if (cache[cache_key]) {
      return cache[cache_key]
    }
    const responseData = await axiosEngineInstance.post(enginePaths.aggregate_category, data, status200).then((resp) => {
      return resp.data
    });
    setCacheData(cache_key, responseData)
    return responseData
  }

  const fetchGetData = async () => {
    setIsLoading(true);

    let localSegmentationFilter = {
      ...activeCategoryFilters,
      ...(activeInsightFilters[policyId] || {}),
    };
    delete localSegmentationFilter[column];

    let localStatisticFilter = {...activeStatisticFilters};
    let localSimilarityQuery = similarityQuery[policyId] || {};
    let localSimilarityFilters = activeSimilarityFilters[policyId] || {};

    if (treeMapFilter) {
      Object.keys(treeMapFilter).forEach((key) => {
        if (key === policyId) {
          Object.assign(localSegmentationFilter, treeMapFilter[key]);
        }
      });
    }

    try {
      let response = await fetchData({
        surveyId: dataSource,
        attribute: column,
        segmentation: localSegmentationFilter,
        statistics: localStatisticFilter,
        categories: predefinedCategories,
        weight: weight,
        questionId: answerVar,
        similarity_query: localSimilarityQuery,
        similarity: localSimilarityFilters,
        region: dataRegion || "US",
      });

      if (response?.rows?.length > 0) {
        let categories = response.rows.map((d) => {
          if (predefinedCategories) {
            for (let predefinedCategory of predefinedCategories) {
              if (predefinedCategory.topic === d.name) {
                return {
                  ...d,
                  summary: predefinedCategory.summary || predefinedCategory.description,
                };
              }
            }
          }
          return {
            ...d,
            summary: "",
          };
        });

        setData(categories);
      }
    } catch (error) {
      console.log("Error fetching categories for ", column, ": ", error);
    } finally {
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    fetchGetData();
  }, [
    dataSource, column, weight, activeCategoryFilters,
    activeStatisticFilters, selectedCategories,
    activeSimilarityFilters, similarityQuery,
    treeMapFilter, activeInsightFilters
  ]);


  useEffect(() => {
    if (column && activeInsightFilters) {
      const insights = activeInsightFilters[policyId] || {};
      let localSegmentationFilter = {...insights}
      let found = false;
      for (var prop in localSegmentationFilter) {
        if (prop === column) {
          found = true;
          setSelectedCategories([...localSegmentationFilter[prop].map((d) => `${d}`)]);
        }
      }
      if (!found) {
        setSelectedCategories([]);
      }
    }
  }, [column, activeInsightFilters]);

  const handleSelectedCategoriesChange = (categories) => {
    let insightsFilter = activeInsightFilters[policyId] || {}
    let localSegmentationFilter = {...insightsFilter}
    if (categories.length > 0) {
      localSegmentationFilter[column] = data.map((d) => d.name).filter((d) => categories.includes(`${d}`));
    } else {
      delete localSegmentationFilter[column];
    }
    let globalFilter = {...activeInsightFilters}
    globalFilter[policyId] = localSegmentationFilter
    dispatch(setInsightsFilter(globalFilter))
    dispatch(setUpdateQA(true));
    setSelectedCategories(categories);
  }
  wrapperProps.actions = [{
    id: 'a3',
    icon: <MuiInfoOutlinedIcon
      color={"grey"}/>,
    action: () => alert('Info del widget'),
    disabled: false,
    show: false,
  }];

  return (
    <WrapperWidgetUI title={title} widgetId={id}
                     isLoading={isLoading} {...wrapperProps}
                     filterEnabled={selectedCategories?.length > 0}>
      {(data && data.length > 0) && (
        <CategoryWidgetUI
          data={data.map((d) => {
            return {name: `${d.name}`, value: d.value, summary: d.summary}
          })}
          selectedCategories={selectedCategories}
          onSelectedCategoriesChange={handleSelectedCategoriesChange}
          color={{
            type: "simple",
            simpleColor: theme.palette.secondary.main,
            customColors: [],
            enabled: true
          }}//poner color fijo o de la organizacion que puede estar en redux o algun provider
          widgetId={id}
          filterable={true}
          maxItems={7}
          searchable={false}
          order={"ranking"}
        />
      )}
      {/* </WidgetWithAlert> */}
    </WrapperWidgetUI>
  );
}

SegmentsWidget.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  column: PropTypes.string.isRequired,
  wrapperProps: PropTypes.object,
  noDataAlertProps: PropTypes.object
};

export default SegmentsWidget;
