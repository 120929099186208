import {IconButton, styled, Typography} from "@mui/material";

export const MuiTitleTypography = styled(Typography)(({ theme }) => ({
  fontFamily: 'Raleway',
  fontStyle:'normal',
  fontWeight: 700,
  textAlign: 'left',
  color:'#424242',
  letterSpacing:'0.0015em',
  lineHeight:'25px',
  fontSize:'21px',
}));

export const MuiIconClose= styled(IconButton)(({ theme }) => ({
  '&:hover': {
    backgroundColor: '#B2EBF2'
  }
}));
