export const boxContainerStyles = {
  display: 'flex',
  flexDirection: 'column',
  maxHeight: '100%',
  overflowY:'hidden'
};

export const divTitleStyles = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  alignContent: 'center',
  paddingLeft:'16px',
  marginBottom:'10px'
};

export const titleStyles = {

};
